import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./components/Layout";
import Auth from "./pages/Auth";
import Chats from "./pages/Chats";
import AdminPage from "./pages/AdminPage";
import UnknownPage from "./pages/404";
import checkSession from "./hooks/checkSession";

function App() {

  const token = JSON.parse(localStorage.getItem("user"));
  const { data: sessionData, loading: sessionLoading, error: sessionError } = checkSession(token?.accessToken);

  return (
    <>
      <Toaster position="buttom-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Auth />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/*" element={<UnknownPage />} />
        </Route>
        {sessionData[0]?.type === "admin" && (
          <Route path="/adminpage" element={<AdminPage />} />
        )}
      </Routes>
    </>
  );
}

export default App;
