import ReCAPTCHA from "react-google-recaptcha";
import { toastEffect } from "./toast";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignIn = (props) => {

  const {
    setSignUP,
    captchaRef,
    loading,
    setLoading
  } = props;

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [type, setType] = useState("password");

  useEffect(() => {
    const savedUsername = localStorage.getItem("saved_email");
    const savedPassword = localStorage.getItem("saved_password");

    if (savedUsername && savedPassword) {
      setEmail(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleShowPass = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const emptyFunction = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const token = captchaRef.current.getValue();

    if (password === "" || email === "") {
      return toastEffect("error", "That is not possible!");
    }

    if (rememberMe) {
      localStorage.setItem("saved_email", email);
      localStorage.setItem("saved_password", password);
    } else {
      localStorage.removeItem("saved_email");
      localStorage.removeItem("saved_password");
    }

    setLoading(true);
    await axios.post(`${process.env.REACT_APP_API_URL}google_captcha`, {
      token: token
    }).then(async (res) => {

      if (!res.data) {
        captchaRef.current.reset();
        toastEffect("error", "Please check captcha first");
        setLoading(false);
        return;
      }

      try {
        await axios.post(`${process.env.REACT_APP_API_URL}loginAuthenticate`, {
          email: email,
          pass: password
        }).then((response) => {
          captchaRef.current.reset();
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("email", email);
            window.location.href = "/chats";
          }
          //console.log(response);
        });
      } catch (err) {
        if (err.response.status === 500) {
          toastEffect("error", "There was a problem with the server");
        } else if (err.response.status === 400) {
          toastEffect("error", err.response.data.msg);
          captchaRef.current.reset();
          setLoading(false);
        } else {
          toastEffect("error", "Error! #101942");
        }
      }

    }).catch((err) => {
      toastEffect("error", "Error! #101943");
    });
  };

  return (
    <div className="py-6 mt-auto mb-auto">
      <div className="flex dark:bg-gray-800 bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        <div className="bg-gray-200/50  dark:bg-gray-700/50 rightside hidden lg:block lg:w-1/2 bg-cover"></div>
        <div className="w-full p-8 lg:w-1/2">
          <p className="text-xl text-gray-600 dark:text-gray-200 text-center font-bold">Mothers and the Family Court</p>
          <a href="#" className="flex items-center justify-center mt-4 bg-gray-50 text-gray-600 dark:text-gray-100 dark:bg-gray-700 rounded-lg shadow-md dark:hover:bg-gray-600 hover:bg-gray-100">
            {/*<div className="px-4 py-3">
              <svg className="h-6 w-6" viewBox="0 0 40 40">
                <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107" />
                <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00" />
                <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50" />
                <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2" />
              </svg>
            </div>
            <h1 className="px-4 py-3 w-5/6 text-center font-bold">Sign in with Google</h1>*/}
          </a>
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 lg:w-1/4"></span>
            {/*<a href="#" className="text-xs text-center text-gray-500 dark:text-gray-200 uppercase">or login with email</a>*/}
            <a href="#" className="text-xs text-center text-gray-500 dark:text-gray-200 uppercase">login with email</a>
            <span className="border-b w-1/5 lg:w-1/4"></span>
          </div>
          <form onSubmit={loading ? emptyFunction : handleSignIn}>
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2">Email Address</label>
              <input onChange={(e) => setEmail(e.target.value)} value={email} className="bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-200 focus:outline-none focus:shadow-outline border border-gray-300 dark:border-gray-500 rounded py-2 px-4 block w-full appearance-none" type="email" />
            </div>

            <div className="mt-6  w-full">
              <label htmlFor="pass" className="block text-sm font-medium leading-none text-gray-800 dark:text-gray-300">
                Password
              </label>
              <div className="relative flex items-center justify-center">
                <input onChange={(e) => setPassword(e.target.value)} value={password} type={type} className="bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-200 border-gray-300 dark:border-gray-500 border rounded leading-none py-3 w-full pl-3 mt-2" required />
                <div className="absolute right-0 mt-2 mr-3 cursor-pointer text-gray-900">
                  <i onClick={handleShowPass} className={`${type === "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}`} />
                </div>
              </div>
            </div>

            {/*<div className="mt-2">
              <p className="text-xs text-gray-500 dark:text-gray-200">Don’t have an account yet? <a onClick={() => setSignUP(true)} className="cursor-pointer text-blue-500 hover:text-blue-600">Sign up</a></p>
            </div>*/}

            <div className="flex items-center justify-between mt-2">
              <p className="text-xs text-gray-500 dark:text-gray-200">Don’t have an account yet? <a onClick={() => setSignUP(true)} className="cursor-pointer text-blue-500 hover:text-blue-600">Sign up</a></p>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input onChange={(e) => setRememberMe(e.target.checked)} id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div>
            </div>

            <div className="m-2">
              <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_KEY} ref={captchaRef} className="g-recaptcha" />
            </div>
            <div className="mt-2">
              <button className="bg-blue-600 text-white font-bold py-2 px-4 w-full rounded hover:bg-blue-700">
                <i className={loading ? "fa-solid fa-sync fa-spin" : "fa-solid fa-arrows-rotate"}></i> {loading ? "Signing in" : "Sign in"}
              </button>
            </div>
          </form>
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 md:w-1/4"></span>
            <a href="#" className="text-xs text-blue-500 hover:text-blue-600">Forgot password?</a>
            <span className="border-b w-1/5 md:w-1/4"></span>
          </div>
        </div>
      </div>
    </div >
  );
};

export default SignIn;