import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function useFetch(token) {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [chatSession, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [action, setAction] = useState({});

  useEffect(() => {
    if (!token) {
      return;
    }
    getProtectedData();
  }, [token]);

  async function getProtectedData() {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}checkSession`, {
        token: token
      }).then(async (response) => {
        await axios.post(`${process.env.REACT_APP_API_URL}getAccount`, {
          email: response.data.email
        }).then((response) => {
          setData(response.data);
          setLoading(false);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getChatSessions`, {
          email: response.data.email
        }).then(async (response_sessions) => {
          await axios.post(`${process.env.REACT_APP_API_URL}getAllConvo`)
            .then(async (response_convo) => {
              await axios.post(`${process.env.REACT_APP_API_URL}getAllSessions`)
                .then((response_allSessions) => {
                  const mergeDATA = response_sessions.data.map(sessions => {
                    let convo = response_convo.data.filter(convo => {
                      return convo.session === sessions.session;
                    });
                    let members = response_allSessions.data.filter(Allsessions => {
                      return Allsessions.session === sessions.session;
                    });
                    return {
                      ...sessions,
                      convo: convo,
                      members
                    };
                  });
                  setChatSessions(mergeDATA);
                });
            });
        });
      });
    } catch (err) {
      setError(err);
      //setAction(window.location.href = "/");
    }
  }

  return { data, chatSession, loading, error, action, getProtectedData };

}
export default useFetch;