import { Link } from "react-router-dom";

const Header = (props) => {

  const {
    currentTheme,
    handleTheme
  } = props;

  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
              <span className="sr-only">Open sidebar</span>
              <i className="fa-solid fa-bars"></i>
            </button>
            <Link to="/" className="flex ml-2 md:mr-24">
              <img src="/family-law_logo192.png" className="h-8 mr-3" alt="FlowBite Logo" />
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-gray-700 dark:text-white">Mothers and the Family Court</span>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="flex items-center ml-3">

              <div title="Chat page" className="mr-5">
                <Link to="/chats" className="flex text-sm text-gray-600 dark:text-gray-200">
                  <i className="fa-solid fa-comments fa-xl"></i>
                </Link>
              </div>

              <div title={currentTheme === "dark" ? "Light Mode" : "Dark Mode"}>
                <button onClick={handleTheme} type="button" className="flex text-sm text-gray-600 dark:text-gray-200">
                  {/*<span className="sr-only">Open user menu</span>*/}
                  <i className={currentTheme === "dark" ? "fa-solid fa-sun fa-xl" : "fa-solid fa-moon fa-xl"}></i>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;