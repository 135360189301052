const Messages = (props) => {

  const {
    key,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
    data,
    buttons,
    sessionData,
    moment
  } = props;

  const styleME = {
    wrapper: `flex flex-row justify-end`,
    container: `messages text-sm text-white grid grid-flow-row gap-2`,
    contentWrapper: `flex items-center flex-row-reverse group`,
    message: `whitespace-pre-wrap mb-1 mt-1 px-4 py-2 bg-blue-500 dark:bg-blue-700 max-w-xs lg:max-w-md`
  };

  const styleThem = {
    wrapper: `flex flex-row justify-start`,
    container: `messages text-sm text-gray-700 grid grid-flow-row gap-2`,
    contentWrapper: `flex items-center group`,
    message: `whitespace-pre-wrap mb-1 mt-1 px-4 py-2 bg-gray-300 dark:bg-gray-800 max-w-xs lg:max-w-md text-gray-700 dark:text-gray-200`
  };

  const sequence = {
    start: `rounded-t-full`,
    end: `rounded-b-full`
  };

  //rounded-l-xl rounded-r-xl
  //rounded-r-full
  // one line - 68 && max - 190

  const friendlyTimestamp = moment(data.created).format('LLLL');
  const hasNewLine = data.message.indexOf('\n') !== -1;
  const charCount = data.message.length;

  return (
    <>

      {showTimestamp && (
        <p className="p-4 text-center text-sm text-gray-500 ">{friendlyTimestamp}</p>
      )}
      <div className={isMine ? styleME.wrapper : styleThem.wrapper}>
        {startsSequence && data.email !== sessionData[0]?.email ? (
          <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
            <img
              className="shadow-md rounded-full w-full h-full object-cover bg-gray-200 dark:bg-gray-800"
              src={data.avatar ? `${process.env.REACT_APP_UPLOAD_LOCATION}${data.avatar}` : `${process.env.REACT_APP_UPLOAD_LOCATION}profile_default.png`}
              alt="avatar" />
          </div>
        ) : (
          <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
            {/*<img className="shadow-md rounded-full w-full h-full object-cover" src="https://randomuser.me/api/portraits/women/33.jpg" alt="" />*/}
          </div>
        )}
        <div className={isMine ? styleME.container : styleThem.container}>
          {/*<div className="flex items-center group">
            <p className="px-6 py-3 rounded-t-full rounded-r-full bg-gray-500 dark:bg-gray-800 max-w-xs lg:max-w-md text-gray-200">Hey! How are you?</p>
            {buttons()}
          </div>*/}
          {startsSequence && data.email !== sessionData[0]?.email && (<p>{data.sender}</p>)}
          <div className={isMine ? styleME.contentWrapper : styleThem.contentWrapper}>

            {data.type === "img" ? (
              <>
                <img className="my-1 max-w-sm max-h-56 rounded-md" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.message}`} alt="img" />
                {/*<img className="my-1 w-auto h-[10rem] rounded-md" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.message}`} alt="img" />*/}
              </>
            ) : (
              <p className={`${isMine ? `${(hasNewLine || charCount > 190) ? "rounded-l-xl rounded-r-xl" : "rounded-l-full"} ${styleME.message}` : `${(hasNewLine || charCount > 190) ? "rounded-l-xl rounded-r-xl" : "rounded-r-full"} ${styleThem.message}`} ${startsSequence ? `${(hasNewLine || charCount > 190) ? "" : sequence.start}` : ""} ${endsSequence ? sequence.end : ""}`}>
                {data.message}
                {/*{hasNewLine ? ' Yes' : ' No'}
                {" Count: " + charCount}*/}
              </p>
            )}
            <a type="button" className="hidden text-xs group-hover:block flex-shrink-0 focus:outline-none mx-1 p-1 rounded-full text-gray-500 hover:text-gray-900 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800">
              {moment(data.created).format('h:mm A')}
            </a>
          </div>
          {/*<div className="flex items-center group">
            <p className="px-6 py-3 rounded-b-full rounded-r-full bg-gray-500 dark:bg-gray-800 max-w-xs lg:max-w-md text-gray-200">Lorem ipsum
              dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Volutpat lacus laoreet non curabitur gravida.</p>
            {buttons()}
          </div>*/}
        </div>
      </div>
      {/*<p className="p-4 text-center text-sm text-gray-500">FRI 3:04 PM</p>*/}

      {/*<div className="flex flex-row justify-end">
        <div className="messages text-sm text-white grid grid-flow-row gap-2">
          <div className="flex items-center flex-row-reverse group">
            <p className="px-6 py-3 rounded-t-full rounded-l-full bg-blue-500 dark:bg-blue-700 max-w-xs lg:max-w-md">Hey! How are you?</p>
            {buttons()}
          </div>
          <div className="flex items-center flex-row-reverse group">
            <p className="whitespace-pre-wrap mb-1 mt-1 px-6 py-3 rounded-l-full bg-blue-500 dark:bg-blue-700 max-w-xs lg:max-w-md">Shall we go for Hiking this weekend?</p>
            {buttons()}
          </div>
          <div className="flex items-center flex-row-reverse group">
            <a className="block w-64 h-64 relative flex flex-shrink-0 max-w-xs lg:max-w-md" href="#">
              <img className="absolute shadow-md w-full h-full rounded-l-lg object-cover" src="https://unsplash.com/photos/8--kuxbxuKU/download?force=true&w=640" alt="hiking" />
            </a>
            {buttons()}
          </div>
          <div className="flex items-center flex-row-reverse group">
            <p className="px-6 py-3 rounded-b-full rounded-l-full bg-blue-500 dark:bg-blue-700 max-w-xs lg:max-w-md">Lorem ipsum
              dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Volutpat lacus laoreet non curabitur gravida.</p>
            {buttons()}
          </div>
        </div>
      </div>*/}
    </>
  );
};

export default Messages;