import { useState } from "react";
import moment from 'moment';

const Accounts = (props) => {

  const {
    allAccounts,
    onlineUsers,
    handleAccountStatus,
    email,
    configs,
    setPublicRoomSessionID,
    handleSavePublicRoomSessionID,
    toggleEdit
  } = props;

  moment.locale('en');

  const [accountSearch, setAccountSearch] = useState("");

  const searchData = allAccounts?.filter((data) => {
    if (accountSearch === '') {
      return data;
    }
    else {
      return data.type.toLowerCase().includes(accountSearch.toLowerCase())
        || data.email.toLowerCase().includes(accountSearch.toLowerCase())
        || data.first_name.toLowerCase().includes(accountSearch.toLowerCase())
        || data.last_name.toLowerCase().includes(accountSearch.toLowerCase())
        || data.contact.toLowerCase().includes(accountSearch.toLowerCase())
        || data.alias.toLowerCase().includes(accountSearch.toLowerCase())
        || moment.utc(data.created).local().startOf('seconds').fromNow().toLowerCase().includes(accountSearch.toLowerCase());
    }
  });

  //console.log(onlineUsers);

  return (
    <div className="p-4 sm:ml-64 bg-gray-100 dark:bg-gray-900 h-full overflow-auto">
      {/*<div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">*/}
      <div className="p-4 dark:border-gray-700 mt-5">

        {/*<div className="border-b border-gray-200 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              <li className="mr-2">
                <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>Profile
                </a>
              </li>
              <li className="mr-2">
                <a href="#" className="inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page">
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>Dashboard
                </a>
              </li>
              <li className="mr-2">
                <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>Settings
                </a>
              </li>
              <li className="mr-2">
                <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>Contacts
                </a>
              </li>
              <li>
                <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">Disabled</a>
              </li>
            </ul>
          </div>*/}


        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">

          <div className="flex items-center justify-between pb-4">
            <div>
              <div
                id="dropdownRadio"
                className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                data-popper-escaped=""
                data-popper-placement="top"
                style={{ position: "absolute", inset: "auto auto 0px 0px", margin: "0px", transform: "translate3d(522.5px, 3847.5px, 0px)" }}
              ></div>
              <div title="This is where to input the room session ID for new approved users" className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <i className="fa-solid fa-comment-dots" />
                </div>
                <input
                  onChange={(e) => setPublicRoomSessionID(e.target.value)}
                  onBlur={handleSavePublicRoomSessionID}
                  defaultValue={configs[0]?.public_room}
                  type="text"
                  placeholder="Public room id here"
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>

            {/*<div className="pr-[33rem]">
              <div title="Current online users" className="relative">
                <div className="text-green-500 absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <i className="fa-solid fa-user-group" />
                </div>
                <input
                  disabled
                  defaultValue={onlineUsers?.length}
                  type="text"
                  placeholder="Public room id here"
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-20 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>*/}

            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                onChange={(e) => setAccountSearch(e.target.value)}
                type="text"
                placeholder="Search for names"
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Alias
                </th>
                <th scope="col" className="px-6 py-3">
                  Contact
                </th>
                <th scope="col" className="px-6 py-3 text-right">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 text-right">
                  Registered
                </th>
                <th scope="col" className="px-6 py-3 text-right">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody className="overflow-hidden">

              {searchData?.reverse().map((data, idx) => {
                if (email !== data.email) {
                  return (
                    <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="w-4 p-4">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input onChange={(e) => handleAccountStatus(e, data.id, data.email, data.alias)} defaultChecked={data.status} type="checkbox" className="sr-only peer" />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </td>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {data.email}
                      </th>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className={`h-2.5 w-2.5 rounded-full ${data.status ? `bg-green-500` : `bg-red-500`} mr-2`}></div>
                          {data.status ? "On" : "Off"}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {data.first_name + " " + data.last_name}
                      </td>
                      <td className="px-6 py-4">
                        {data.alias}
                      </td>
                      <td className="px-6 py-4">
                        {data.contact}
                      </td>
                      <td className="px-6 py-4 text-right">
                        {data.type}
                      </td>
                      <td title={moment(data.created).format('MMMM D, yyyy h:mm a')} className="cursor-help px-6 py-4 text-right">
                        {moment.utc(data.created).local().startOf('seconds').fromNow()}
                      </td>
                      <td className="px-6 py-4 text-green-500 text-right">
                        <i
                          onClick={() => toggleEdit({
                            accountID: data.id,
                            accountEmail: data.email,
                            accountFirstName: data.first_name,
                            accountLastName: data.last_name,
                            accountAlias: data.alias,
                            accountContact: data.contact,
                            accountType: data.type,
                            accountAvatar: data.avatar
                          })}
                          className="fa-solid fa-pen-to-square cursor-pointer fa-lg hover:text-green-600" />
                      </td>
                    </tr>
                  );
                }
              })}


            </tbody>
          </table>
        </div>


      </div>
    </div>
  );
};

export default Accounts;