import moment from 'moment';
import { useState } from 'react';

const Logs = (props) => {

  const {
    allLogs
  } = props;

  moment.locale('en');
  const [logSearch, setLogSearch] = useState("");


  const searchLog = allLogs?.filter((data) => {
    if (logSearch === '') {
      return data;
    }
    else {
      return data.email.toLowerCase().includes(logSearch.toLowerCase())
        || data.ip.toLowerCase().includes(logSearch.toLowerCase())
        || data.city.toLowerCase().includes(logSearch.toLowerCase())
        || data.country_name.toLowerCase().includes(logSearch.toLowerCase())
        || data.country_code.toLowerCase().includes(logSearch.toLowerCase())
        || data.postal.toLowerCase().includes(logSearch.toLowerCase())
        || data.latitude.toLowerCase().includes(logSearch.toLowerCase())
        || data.longitude.toLowerCase().includes(logSearch.toLowerCase())
        || data.state.toLowerCase().includes(logSearch.toLowerCase())
        || moment.utc(data.recorded).local().startOf('seconds').fromNow().toLowerCase().includes(logSearch.toLowerCase());
      //moment.utc(data.recorded).local().startOf('seconds').fromNow()
    }
  });

  return (
    <div className="p-4 sm:ml-64 bg-gray-100 dark:bg-gray-900 h-full overflow-auto">
      <div className="p-4 dark:border-gray-700 mt-5">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">

          <div className="flex items-center justify-between pb-4">
            <div>
              <div
                id="dropdownRadio"
                className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                data-popper-escaped=""
                data-popper-placement="top"
                style={{ position: "absolute", inset: "auto auto 0px 0px", margin: "0px", transform: "translate3d(522.5px, 3847.5px, 0px)" }}
              >
              </div>
            </div>
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                onChange={(e) => setLogSearch(e.target.value)}
                type="text"
                placeholder="Search for logs"
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  IP
                </th>
                <th scope="col" className="px-6 py-3">
                  Country
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3 text-right">
                  Recorded
                </th>
              </tr>
            </thead>
            <tbody className="overflow-hidden">

              {searchLog.reverse().map((data, idx) => (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {data.email}
                  </th>
                  <td className="px-6 py-4">
                    {data.ip}
                  </td>
                  <td className="px-6 py-4">
                    {`${data.city}, ${data.state}, ${data.country_name}, ${data.country_code}, ${data.postal}`}
                  </td>
                  <td className="px-6 py-4">
                    {`${data.latitude}, ${data.longitude}`}
                  </td>
                  <td title={moment(data.recorded).format('MMMM D, yyyy h:mm a')} className="cursor-help px-6 py-4 text-right">
                    {moment.utc(data.recorded).local().startOf('seconds').fromNow()}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>


      </div>
    </div>
  );
};

export default Logs;