import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";
import LeftSide from "./LeftSide";
import Middle from "./Middle";
import RightSide from "./RightSide";
import checkSession from "../hooks/checkSession";
import ModalAvatar from "./ModalAvatar";
import ModalLogo from "./ModalLogo";
import { toastEffect } from "./toast";

const Chats = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionID = params.get("session");

  const token = JSON.parse(localStorage.getItem("user"));
  const email = localStorage.getItem("email");
  const admintabLocalStorage = localStorage.getItem("admintab");

  const [adminTab, setAdminTab] = useState(admintabLocalStorage ? JSON.parse(admintabLocalStorage) : false);
  const [theSessionID, setSessionID] = useState("");
  const [convo, setConvo] = useState([]);
  const [session, setSession] = useState([]);
  const [socket, setSocket] = useState(null);
  const [refreshConvo, setRefreshConvo] = useState(0);
  const [refreshOnlineUsers, setRefreshOnlineUsers] = useState(0);
  const [refreshImages, setRefreshImages] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [openIMGModal, setOpenIMGModal] = useState(false);
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [logos, setLogos] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);


  const { data: sessionData, chatSession, loading: sessionLoading, error: sessionError, getProtectedData: refetch } = checkSession(token?.accessToken || "empty");

  //console.log("Data", sessionData);
  //console.log("Loading", sessionLoading);
  //console.log("Error", sessionError?.response);
  //console.log("chatSession", chatSession);

  //Web Socket
  useEffect(() => {
    const webSocket = () => {
      setSocket(io.connect(process.env.REACT_APP_API_URL));
    };
    webSocket();
  }, []);

  //Emits online users to the server realtime
  useEffect(() => {
    if (!email) {
      return;
    }
    const setUser = () => {
      socket?.emit("newUser", email);
    };
    setUser();
  }, [socket]);

  useEffect(() => {
    const updateNewChat = () => {
      socket?.on("updateOnlineUsers", data => {
        if (data) {
          setRefreshOnlineUsers(old => old + 1);
        }
      });
    };
    updateNewChat();
  }, [socket]);

  const toggleAdminSideTab = () => {
    if (adminTab) {
      setAdminTab(false);
      localStorage.setItem("admintab", false);
    } else {
      setAdminTab(true);
      localStorage.setItem("admintab", true);
    }
  };

  useEffect(() => {
    const getPrompts = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}getOnlineUsers`)
          .then((res) => {
            setOnlineUsers(res.data);
          });
      } catch (err) {
        toastEffect("error", "Error! #101926");
      }
    };
    getPrompts();
  }, [refreshOnlineUsers]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}getAllUsers`)
          .then((result) => {
            setAllUsers(result.data);
          });
      } catch (err) {
        toastEffect("error", "Error! #101927");
      }
    };
    getAllUsers();
  });

  useEffect(() => {
    const getData = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}getConvo`, {
          sessionID: sessionID || chatSession[0]?.session,
          email: sessionData[0]?.email
        }).then((result) => {
          setConvo(result.data);
          //console.log(result.data);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getSession`, {
          sessionID: sessionID || chatSession[0]?.session,
          email: email
        }).then((result) => {
          setSession(result.data);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getAvatars`, {
          email: sessionData[0]?.email
        }).then((result) => {
          setAvatars(result.data);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getLogos`, {
          session: sessionID || chatSession[0]?.session
        }).then((result) => {
          setLogos(result.data);
          //console.log("getLogos", result.data);
        });
      } catch (err) {
        toastEffect("error", "Error! #101928");
        //console.log(err);
      }
    };
    getData();
  }, [refreshConvo, chatSession[0]?.session]);

  {/*useEffect(() => {
    const getImages = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}getAvatars`, {
          email: sessionData[0]?.email
        }).then((result) => {
          setAvatars(result.data);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getLogos`, {
          session: sessionID || chatSession[0]?.session
        }).then((result) => {
          setLogos(result.data);
          //console.log("getLogos", result.data);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getImages();
  }, [refreshImages, chatSession[0]?.session]);*/}

  //console.log(sessionID, chatSession[0]?.session);

  const chatSessionFtSession = session.map(session => {
    let _session = chatSession.filter(chatSession => {
      return chatSession.session === session.session;
    });
    return {
      ...session,
      _session: _session[0]
    };
  });

  const getConvo = async (sessionDATA, _email) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}getConvo`, {
        sessionID: sessionDATA,
        email: email
      }).then((result) => {
        setConvo(result.data);
        //console.log("OnlineUsers", onlineUsers);
        //console.log("chatSessionFtSession", chatSessionFtSession);
        //console.log("getConvo", result.data);
        //console.log("chatSession", chatSession);
        //console.log("getSession", session);
        //console.log("Accounts", sessionData);
        //console.log("AllUsers", allUsers);
        //console.log("UserAvatars", avatars);
        //console.log("LOGOS", logos);
      });
      await axios.post(`${process.env.REACT_APP_API_URL}getSession`, {
        sessionID: sessionDATA,
        email: email
      }).then((result) => {
        setSession(result.data);
      });
      await axios.post(`${process.env.REACT_APP_API_URL}getLogos`, {
        session: sessionDATA
      }).then((result) => {
        setLogos(result.data);
      });
    } catch (err) {
      toastEffect("error", "Error! #101929");
      //console.log(err);
    }
  };

  /*const getSession = async (sessionDATA) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}getSession`, {
        sessionID: sessionDATA,
        email: email
      }).then((result) => {
        setSession(result.data);
      });
    } catch (err) {
      console.log(err);
    }
  };*/

  function handleSession(session, idx) {
    window.history.pushState("Session", "Title", `?session=${session}`);
    localStorage.setItem("currentIDX", idx);
    setSessionID(session);
    getConvo(session);
  }

  return (
    <main className="flex-grow flex flex-row min-h-0">

      <LeftSide
        socket={socket}
        sessionData={sessionData}
        sessionID={sessionID}
        theSessionID={theSessionID}
        chatSession={chatSession}
        refetch={refetch}
        handleSession={handleSession}
      />

      <Middle
        theSessionID={theSessionID}
        socket={socket}
        session={session}
        convo={convo}
        sessionID={sessionID}
        chatSession={chatSession}
        sessionData={sessionData}
        getConvo={getConvo}
        refetch={refetch}
        setConvo={setConvo}
        toggleAdminSideTab={toggleAdminSideTab}
        setRefreshConvo={setRefreshConvo}
        chatSessionFtSession={chatSessionFtSession}
      />

      {adminTab && (
        <RightSide
          sessionID={sessionID}
          theSessionID={theSessionID}
          email={email}
          onlineUsers={onlineUsers}
          socket={socket}
          sessionData={sessionData}
          allUsers={allUsers}
          convo={convo}
          session={session}
          chatSession={chatSession}
          chatSessionFtSession={chatSessionFtSession}
          openIMGModal={openIMGModal}
          openLogoModal={openLogoModal}
          setSession={setSession}
          refetch={refetch}
          setOpenIMGModal={setOpenIMGModal}
          setOpenLogoModal={setOpenLogoModal}
        />
      )}

      {openIMGModal && (
        <ModalAvatar
          chatSessionFtSession={chatSessionFtSession}
          avatars={avatars}
          sessionData={sessionData}
          setAvatars={setAvatars}
          refetch={refetch}
          setOpenIMGModal={setOpenIMGModal}
          setRefreshConvo={setRefreshConvo}
        />
      )}

      {openLogoModal && (
        <ModalLogo
          session={session}
          sessionID={sessionID}
          logos={logos}
          sessionData={sessionData}
          chatSessionFtSession={chatSessionFtSession}
          setSession={setSession}
          refetch={refetch}
          setLogos={setLogos}
          setRefreshImages={setRefreshImages}
          setOpenLogoModal={setOpenLogoModal}
          setRefreshConvo={setRefreshConvo}
        />
      )}

    </main>
  );
};

export default Chats;;;