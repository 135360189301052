import { useState, useRef, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import { toastEffect } from "./toast";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import getTimeDiff from './TimeDiff';
import Messages from "./Messages";

const Middle = (props) => {

  const {
    toggleAdminSideTab,
    convo,
    sessionData,
    setConvo,
    sessionID,
    chatSession,
    session,
    socket,
    refetch,
    setRefreshConvo,
    getConvo,
    chatSessionFtSession,
    theSessionID
  } = props;

  moment.locale('en');

  const textAreaRef = useRef(null);
  const bottomRef = useRef(null);
  const emojiRef = useRef(null);
  const fileInputRef = useRef(null);

  const [message, setMessage] = useState("");
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [file, setFile] = useState("");
  const [imagePreview, setImagePreview] = useState('');
  const [visible, setVisible] = useState(false);
  const [triggerScrollDown, setTriggerScrollDown] = useState(0);

  //new chat update realtime
  useEffect(() => {
    const updateNewChat = () => {
      socket?.on("newChat", data => {
        if (data) {
          //setRefreshConvo(oldCount => oldCount + 1);
          //renderMessages();
          getConvo(data.session);
          refetch();
        }
      });
    };
    updateNewChat();
  }, [socket]);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    if (textAreaRef.current.scrollHeight >= 200) {
      textAreaRef.current.style.height = 200 + "px";
      setShowScrollbar(true);
    } else {
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
      setShowScrollbar(false);
    }
  };

  useEffect(() => {
    if (file && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [file]);

  useEffect(resizeTextArea, [message]);

  /**for scrolling effect */
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [convo, triggerScrollDown]);

  const handleKeypress = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (message.trim().length === 0 && !imagePreview) {
        setMessage("");
      } else {
        handleSendChat(e);
      }
    }
  };

  async function handleSendChat(e) {
    e.preventDefault();
    e.stopPropagation();

    if (theSessionID === "" && chatSession[0]?.session === "") {
      return;
    }

    if (message.trim().length === 0 && !imagePreview || message === "" && !imagePreview) {
      return;
    }

    if (message && message.trim().length !== 0) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}sendChat`, {
          sessionID: theSessionID || chatSession[0]?.session,
          email: sessionData[0]?.email,
          sender: sessionData[0]?.alias,
          message: message
        }).then(async (result) => {
          await setConvo((prev) => [...prev, { email: sessionData[0]?.email, message: message }]);
          setMessage("");
          await socket.emit("sendChat", {
            session: theSessionID || chatSession[0]?.session
          });
        });
      } catch (err) {
        toastEffect("error", "Error! #101931");
        //console.log(err);
      }
    }

    if (imagePreview) {
      const formData = new FormData();
      formData.append('file', file);
      let details = {
        sessionID: theSessionID || chatSession[0]?.session,
        email: sessionData[0]?.email,
        sender: sessionData[0]?.alias,
      };
      for (let key in details) {
        formData.append(key, details[key]);
      }
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}sendIMG`, formData)
          .then(async (result) => {
            setMessage("");
            setImagePreview("");
            setHasFile(false);
            await socket.emit("sendChat", {
              session: theSessionID || chatSession[0]?.session
            });
          });
      } catch (err) {
        if (err.response.status === 500) {
          toastEffect("error", "There was a problem with the server");
        } else if (err.response.status === 400) {
          toastEffect("error", err.response.data.msg);
        } else {
          toastEffect("error", "Error! #101932");
        }
      }
    }

  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setIsEmojiVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [emojiRef]);

  function handleEmojiClick() {
    setIsEmojiVisible(!isEmojiVisible);
  }

  function toggleShowEmoji() {
    if (isEmojiVisible) {
      setIsEmojiVisible(false);
    } else {
      setIsEmojiVisible(true);
    }
  }

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setMessage(message + emoji);
  };

  const renderMessages = () => {
    let i = 0;
    let messageCount = convo.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = convo[i - 1];
      let current = convo[i];
      let next = convo[i + 1];
      let isMine = current.email === sessionData[0]?.email;
      let currentMoment = moment(current.created);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.created);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.email === current.email;

        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 2) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.created);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.email === current.email;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Messages
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          sessionData={sessionData}
          moment={moment}
          buttons={buttons}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

  function handleButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileInputChange(e) {
    const hasFile = fileInputRef.current.files.length > 0;
    const file = fileInputRef.current.files[0];
    if (!file) {
      return;
    }
    //const filename = fileInputRef.current.files;
    setImagePreview(URL.createObjectURL(file));
    setHasFile(hasFile);
    setFile(e.target.files[0]);
    //setFileName(filename[0]?.name);
    //console.log(filename);
  }

  function handleIMGClose() {
    setImagePreview("");
    setHasFile(false);
  }

  useEffect(() => {
    function handleScroll(event) {
      const deltaY = event.deltaY;
      if (deltaY < 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, []);

  function handleScrollDown() {
    setTriggerScrollDown(old => old + 1);
    setVisible(false);
  }

  function buttons() {
    return (
      <>
        <button type="button" className="hidden group-hover:block flex flex-shrink-0 focus:outline-none mx-1 block rounded-full text-gray-500 hover:text-gray-900 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800 w-8 h-8 p-2">
          <svg viewBox="0 0 24 24" className="w-full h-full fill-current">
            <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
        </button>
        <button type="button" className="hidden group-hover:block flex flex-shrink-0 focus:outline-none mx-1 block rounded-full text-gray-500 hover:text-gray-900 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800 w-8 h-8 p-2">
          <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
            <path d="M19,16.685c0,0-2.225-9.732-11-9.732V2.969L1,9.542l7,6.69v-4.357C12.763,11.874,16.516,12.296,19,16.685z" />
          </svg>
        </button>
        <button type="button" className="hidden group-hover:block flex flex-shrink-0 focus:outline-none mx-1 block rounded-full text-gray-500 hover:text-gray-900 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800 w-8 h-8 p-2">
          <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
            <path d="M10.001,7.8C8.786,7.8,7.8,8.785,7.8,10s0.986,2.2,2.201,2.2S12.2,11.215,12.2,10S11.216,7.8,10.001,7.8z M3.001,7.8C1.786,7.8,0.8,8.785,0.8,10s0.986,2.2,2.201,2.2S5.2,11.214,5.2,10S4.216,7.8,3.001,7.8z M17.001,7.8 C15.786,7.8,14.8,8.785,14.8,10s0.986,2.2,2.201,2.2S19.2,11.215,19.2,10S18.216,7.8,17.001,7.8z" />
          </svg>
        </button>
      </>
    );
  }

  return (
    <section className="flex flex-col flex-auto border-l border-gray-300 dark:border-gray-800">
      <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center shadow">

        <div className="flex">
          <div className="w-12 h-12 mr-4 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover" src={session[0]?.logo ? `${process.env.REACT_APP_UPLOAD_LOCATION}${session[0]?.logo}` : `${process.env.REACT_APP_UPLOAD_LOCATION}chat_logo.png`} alt="" />
          </div>
          <div className="text-sm text-gray-700 dark:text-gray-200">
            <p
              title={session[0]?.title ? session[0]?.title : chatSession[0]?.title}
              className="truncate w-[36rem] font-bold">
              {session[0]?.title ? session[0]?.title : chatSession[0]?.title}
              {chatSessionFtSession[0]?._session?.members.length > 3 && (
                <b className="font-light text-blue-400">{` +${chatSessionFtSession[0]?._session?.members.length - 3} other${(chatSessionFtSession[0]?._session?.members.length - 3) >= 2 ? "s" : ""}`}</b>
              )}
            </p>
            <p className={`text-gray-500 ${chatSessionFtSession[0]?._session?.members.length > 3 && "truncate w-48"}`}>
              {chatSessionFtSession[0]?._session?.members.length > 2 ? (
                <>
                  {chatSessionFtSession[0]?._session?.members.map((data) => data.alias).join(", ")}
                  {/*...<small className="font-light text-blue-400">+32 others</small>*/}
                </>
              ) : (
                <>
                  {/*Acvtive {getTimeDiff(convo.slice().reverse()[0]?.created ? convo.slice().reverse()[0]?.created : chatSession[0]?.convo[0]?.created)}*/}
                  {convo.slice().reverse()[0]?.created ? `Active ${getTimeDiff(convo.slice().reverse()[0]?.created)}` : "New Session"}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="flex">
          {/*<a href="#" className="block rounded-full hover:bg-gray-700 bg-gray-300 dark:bg-gray-800 w-10 h-10 p-2">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current text-blue-500">
              <path d="M11.1735916,16.8264084 C7.57463481,15.3079672 4.69203285,12.4253652 3.17359164,8.82640836 L5.29408795,6.70591205 C5.68612671,6.31387329 6,5.55641359 6,5.00922203 L6,0.990777969 C6,0.45097518 5.55237094,3.33066907e-16 5.00019251,3.33066907e-16 L1.65110039,3.33066907e-16 L1.00214643,8.96910337e-16 C0.448676237,1.13735153e-15 -1.05725384e-09,0.445916468 -7.33736e-10,1.00108627 C-7.33736e-10,1.00108627 -3.44283713e-14,1.97634814 -3.44283713e-14,3 C-3.44283713e-14,12.3888407 7.61115925,20 17,20 C18.0236519,20 18.9989137,20 18.9989137,20 C19.5517984,20 20,19.5565264 20,18.9978536 L20,18.3488996 L20,14.9998075 C20,14.4476291 19.5490248,14 19.009222,14 L14.990778,14 C14.4435864,14 13.6861267,14.3138733 13.2940879,14.7059121 L11.1735916,16.8264084 Z" />
            </svg>
          </a>
          <a href="#" className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 ml-4">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current text-blue-500">
              <path d="M0,3.99406028 C0,2.8927712 0.894513756,2 1.99406028,2 L14.0059397,2 C15.1072288,2 16,2.89451376 16,3.99406028 L16,16.0059397 C16,17.1072288 15.1054862,18 14.0059397,18 L1.99406028,18 C0.892771196,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M8,14 C10.209139,14 12,12.209139 12,10 C12,7.790861 10.209139,6 8,6 C5.790861,6 4,7.790861 4,10 C4,12.209139 5.790861,14 8,14 Z M8,12 C9.1045695,12 10,11.1045695 10,10 C10,8.8954305 9.1045695,8 8,8 C6.8954305,8 6,8.8954305 6,10 C6,11.1045695 6.8954305,12 8,12 Z M16,7 L20,3 L20,17 L16,13 L16,7 Z" />
            </svg>
          </a>*/}
          <a onClick={toggleAdminSideTab} className="cursor-pointer block rounded-full text-blue-500 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800 w-10 h-10 p-2 ml-4 flex items-center justify-center">
            <i className="fa-solid fa-ellipsis" />
          </a>
        </div>

      </div>

      <div className="chat-body p-4 flex-1 overflow-y-scroll">

        {/** Messages here---------------------------------------------------------------------------------------------------------------------------------------------------- */}
        {renderMessages()}
        {/*<p className="p-4 text-center text-sm text-gray-500">FRI 3:04 PM</p>*/}
        <div ref={bottomRef} className="h-3"></div>
      </div>

      <form onSubmit={handleSendChat}>

        {/*<div className="relative rounded-xl top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center p-2 mr-20 ml-20">
          <img className="my-1 mx-1 w-auto h-[3rem] rounded-md" src="/uploads/1677053094.png" alt="img" />
        </div>*/}

        <div className="chat-footer flex-none">

          {hasFile && (
            <div className="rounded-xl bg-gray-300 dark:bg-gray-700 relative top-0 left-0 right-0 bottom-0 z-50 flex justify-start items-start p-2 mr-20 ml-20">
              <img className="my-1 mx-1 w-auto h-[3rem] rounded-md" src={imagePreview} alt="img" />
              {imagePreview && (
                <button className="absolute top-0 right-0 px-2 py-1 text-white" onClick={handleIMGClose}>
                  <i className="fa-solid fa-circle-xmark" />
                </button>
              )}
            </div>

          )}


          <div className="flex flex-row items-center p-4">
            <input onChange={handleFileInputChange} ref={fileInputRef} type="file" className="hidden" />
            <button onClick={handleButtonClick} type="button" className="w-10 h-10 mb-1 p-3 mx-2 group-hover:block flex flex-shrink-0 focus:outline-none block rounded-full text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800">
              <i className="fa-solid fa-image" />
            </button>

            {/*<button type="button" className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M11,13 L8,10 L2,16 L11,16 L18,16 L13,11 L11,13 Z M0,3.99406028 C0,2.8927712 0.898212381,2 1.99079514,2 L18.0092049,2 C19.1086907,2 20,2.89451376 20,3.99406028 L20,16.0059397 C20,17.1072288 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M15,9 C16.1045695,9 17,8.1045695 17,7 C17,5.8954305 16.1045695,5 15,5 C13.8954305,5 13,5.8954305 13,7 C13,8.1045695 13.8954305,9 15,9 Z" />
            </svg>
          </button>
          <button type="button" className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M10,1.6c-4.639,0-8.4,3.761-8.4,8.4s3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4S14.639,1.6,10,1.6z M15,11h-4v4H9  v-4H5V9h4V5h2v4h4V11z" />
            </svg>
          </button>
          <button type="button" className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M0,6.00585866 C0,4.89805351 0.893899798,4 2.0048815,4 L5,4 L7,2 L13,2 L15,4 L17.9951185,4 C19.102384,4 20,4.89706013 20,6.00585866 L20,15.9941413 C20,17.1019465 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1029399 0,15.9941413 L0,6.00585866 Z M10,16 C12.7614237,16 15,13.7614237 15,11 C15,8.23857625 12.7614237,6 10,6 C7.23857625,6 5,8.23857625 5,11 C5,13.7614237 7.23857625,16 10,16 Z M10,14 C11.6568542,14 13,12.6568542 13,11 C13,9.34314575 11.6568542,8 10,8 C8.34314575,8 7,9.34314575 7,11 C7,12.6568542 8.34314575,14 10,14 Z" />
            </svg>
          </button>
          <button type="button" className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M9,18 L9,16.9379599 C5.05368842,16.4447356 2,13.0713165 2,9 L4,9 L4,9.00181488 C4,12.3172241 6.6862915,15 10,15 C13.3069658,15 16,12.314521 16,9.00181488 L16,9 L18,9 C18,13.0790094 14.9395595,16.4450043 11,16.9378859 L11,18 L14,18 L14,20 L6,20 L6,18 L9,18 L9,18 Z M6,4.00650452 C6,1.79377317 7.79535615,0 10,0 C12.209139,0 14,1.79394555 14,4.00650452 L14,8.99349548 C14,11.2062268 12.2046438,13 10,13 C7.790861,13 6,11.2060545 6,8.99349548 L6,4.00650452 L6,4.00650452 Z" />
            </svg>
          </button>*/}
            <div className="relative flex-grow">
              {visible && convo.length !== 0 && (
                <div className="absolute bottom-20 -right-10 z-50">
                  <button onClick={handleScrollDown} type="button" className="text-lg w-10 h-10 text-blue-500 bg-gray-100 rounded-full">
                    <i className="fa-solid fa-arrow-down" />
                  </button>
                </div>
              )}
              <label ref={emojiRef}>
                <textarea
                  disabled={!theSessionID && !chatSession[0]?.session ? true : false}
                  //disabled={convo.length > 0 ? false : true}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeypress}
                  ref={textAreaRef}
                  type="text"
                  rows="1"
                  value={message}
                  placeholder="Aa"
                  className={`rounded-3xl py-2 pl-3 pr-10 w-full border ${!showScrollbar && "scrollbar-hide"} border-gray-200 dark:border-gray-800 dark:focus:border-gray-700 dark:bg-gray-800 focus:bg-gray-100 dark:focus:bg-gray-900 focus:outline-none text-gray-700 dark:text-gray-200 focus:shadow-md transition duration-300 ease-in`}
                />
                {/*<textarea
                autoFocus
                type="text"
                id="chat"
                rows="1"
                placeholder="Your message..."
                className="scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md border-0 bg-transparent outline-none m-0 resize-none pl-2 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0 block mx-1 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />*/}

                <button onClick={toggleShowEmoji} title="Emojis" type="button" className="absolute top-3 right-0 mt-2 mr-2 flex flex-shrink-0 focus:outline-none block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
                  <i className="fa-solid fa-face-laugh fa-xl" />
                </button>
                {isEmojiVisible && (
                  <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex justify-end items-end mb-[3rem] mr-2">
                    <Picker previewPosition="none" data={data} onEmojiSelect={addEmoji} />
                  </div>
                )}

              </label>
            </div>

            {/*<button type="button" className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 w-6 h-6">
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M11.0010436,0 C9.89589787,0 9.00000024,0.886706352 9.0000002,1.99810135 L9,8 L1.9973917,8 C0.894262725,8 0,8.88772964 0,10 L0,12 L2.29663334,18.1243554 C2.68509206,19.1602453 3.90195042,20 5.00853025,20 L12.9914698,20 C14.1007504,20 15,19.1125667 15,18.000385 L15,10 L12,3 L12,0 L11.0010436,0 L11.0010436,0 Z M17,10 L20,10 L20,20 L17,20 L17,10 L17,10 Z" />
            </svg>
          </button>*/}

            <button title="Send" type="submit" className="w-10 h-10 mb-1 p-3 mx-2 group-hover:block flex flex-shrink-0 focus:outline-none block rounded-full text-blue-500 dark:text-blue-600 hover:text-blue-600 dark:hover:text-blue-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800">
              <i className="fa-solid fa-paper-plane" />
            </button>


          </div>
        </div>
      </form>

    </section>

  );
};

export default Middle;