import { useState } from "react";
import axios from "axios";
import axiosRateLimit from "axios-rate-limit";
import { toastEffect } from "./toast";

const SignUp = (props) => {

  const {
    captchaRef,
    loading,
    setLoading,
    setSignUP
  } = props;

  const [email, setEmail] = useState("");
  const [alias, setAlias] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");

  const api = axiosRateLimit(axios.create(), {
    maxRequests: 5, // maxRequests: 3-5 requests per minute
    perMilliseconds: 30000, // perMilliseconds: 20000-30000 milliseconds (20-30 seconds)
  });

  const handleShowPass = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const emptyFunction = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSignUP = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log(alias.length);

    const token = captchaRef.current.getValue();

    if (email === "" || firstName === "" || lastName === "" || contact === "" || password === "" || alias === "") {
      return toastEffect("error", "Empty fields! That's not possible.");
    }

    if (alias.length > 15 || alias.length < 5) {
      return toastEffect("error", "This is not possible.");
    }

    setLoading(true);

    try {
      await api.post(`${process.env.REACT_APP_API_URL}accountSignup`, {
        alias: alias,
        email: email,
        firstName: firstName,
        lastName: lastName,
        contact: contact,
        password: password
      }).then((res) => {
        captchaRef.current.reset();
        toastEffect("success", "Registered successfully.");
        setEmail("");
        setFirstName("");
        setLastName("");
        setContact("");
        setPassword("");
        setAlias("");
        setLoading(false);
      });
    } catch (err) {
      if (err.response.status === 500) {
        toastEffect("error", "There was a problem with the server");
      } else if (err.response.status === 400) {
        toastEffect("error", err.response.data.msg);
        captchaRef.current.reset();
        setLoading(false);
      } else {
        toastEffect("error", "Error! #101944");
      }
    }

  };

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

            <form onSubmit={loading ? emptyFunction : handleSignUP}>

              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <p tabIndex="0" className="focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 dark:text-gray-300">Sign up for new account</p>
                <div className="absolute top-0 right-0 mt-2 mr-2">
                  <button onClick={() => setSignUP(false)}>
                    <span className="text-2xl font-bold text-gray-800 dark:text-gray-300"><i className="fa-regular fa-circle-xmark"></i></span>
                  </button>
                </div>

                {/*<p onClick={() => setSignUP(false)} tabIndex="0" className="focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500">Already have account?
                <a className="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-blue-500 cursor-pointer"> Login here</a>
                </p>

                <button aria-label="Continue with google" role="button" className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-10">
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg2.svg" alt="google" />
                  <p className="text-base font-medium ml-4 text-gray-700 dark:text-gray-300">Continue with Google</p>
                </button>*/}

                <div className="w-full flex items-center justify-between py-5">
                  <hr className="w-full bg-gray-400" />
                  {/*<p className="text-base font-medium leading-4 px-2.5 text-gray-400">OR</p>*/}
                  <hr className="w-full bg-gray-400" />
                </div>

                <div className="mb-3  w-full">
                  <label id="alias" className="text-sm font-medium leading-none text-gray-800 dark:text-gray-300">
                    Anonymous Alias <small className="text-gray-500">(Max. of 15 characters only)</small>
                  </label>
                  <input onChange={(e) => setAlias(e.target.value)} value={alias} placeholder="Anonymous alias" minLength="5" maxLength="15" aria-labelledby="alias" type="text" className="bg-gray-200 border rounded leading-none text-gray-800 py-3 w-full pl-3 mt-2" required />
                </div>

                <div className="flex flex-wrap -mx-3 mb-3 md:mb-0">
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-gray-700 dark:text-gray-300 text-sm font-medium mb-2" htmlFor="grid-first-name">
                      First Name
                    </label>
                    <input onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" placeholder="First name" id="grid-first-name" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" required />

                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-gray-700 dark:text-gray-300 text-sm font-medium mb-2" htmlFor="grid-last-name">
                      Last Name
                    </label>
                    <input onChange={(e) => setLastName(e.target.value)} value={lastName} type="text" placeholder="Last name" id="grid-last-name" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required />
                  </div>
                </div>
                <div>
                  <label id="email" className="text-sm font-medium leading-none text-gray-800 dark:text-gray-300">
                    Email
                  </label>
                  <input onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email address" aria-labelledby="email" type="email" className="bg-gray-200 border rounded leading-none text-gray-800 py-3 w-full pl-3 mt-2" required />
                </div>
                <div className="mt-3  w-full">
                  <label id="username" className="text-sm font-medium leading-none text-gray-800 dark:text-gray-300">
                    Contact
                  </label>
                  <input onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact number" aria-labelledby="username" type="number" className="bg-gray-200 border rounded leading-none text-gray-800 py-3 w-full pl-3 mt-2" required />
                </div>
                <div className="mt-3  w-full">
                  <label htmlFor="pass" className="text-sm font-medium leading-none text-gray-800 dark:text-gray-300">
                    New Password
                  </label>
                  <div className="relative flex items-center justify-center">
                    <input onChange={(e) => setPassword(e.target.value)} value={password} placeholder="New password" id="pass" type={type} className="text-gray-800 bg-gray-200 border rounded leading-none py-3 w-full pl-3 mt-2" required />
                    <div className="absolute right-0 mt-2 mr-3 cursor-pointer text-gray-700">
                      <i onClick={handleShowPass} className={`${type === "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}`} />
                    </div>
                  </div>
                </div>
                {/*<div className="mt-3 w-full">
                </div>*/}
                <div className="mt-6">
                  <button role="button" className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full">
                    <i className={loading ? "fa-solid fa-sync fa-spin" : "fa-solid fa-arrows-rotate"}></i> {loading ? "Creating..." : "Create my account"}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;