const getTimeDiff = (datetime) => {
  const date = new Date(datetime);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const diffInSeconds = Math.floor(diff / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}sec${diffInSeconds === 1 ? '' : '\'s'}`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);

  if (diffInMinutes < 60) {
    return `${diffInMinutes}min${diffInMinutes === 1 ? '' : 's'}`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);

  if (diffInHours < 24) {
    return `${diffInHours}hr${diffInHours === 1 ? '' : 's'}`;
  }

  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays < 30) {
    return `${diffInDays}day${diffInDays === 1 ? '' : 's'}`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInMonths < 12) {
    return `${diffInMonths}month${diffInMonths === 1 ? '' : 's'}`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  if (isNaN(diffInYears)) {
    return "New";
  } else {
    return `${diffInYears} year${diffInYears === 1 ? '' : 's'}`;
  }
};

export default getTimeDiff;