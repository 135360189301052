import { Link } from "react-router-dom";

const LeftSide = (props) => {

  const {
    setcurrentTab,
    currentTab,
    _currentTab,
    onlineUsers,
    logout
  } = props;

  return (
    <div className="hidden dark:bg-gray-800 md:fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col">
      <div className="flex h-full min-h-0 flex-col">
        <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-gray-800 dark:border-white/20">
          <nav className="flex h-full flex-1 flex-col space-y-1 p-2">

            <a className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-500/10 transition-colors duration-200 text-gray-700 dark:text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-gray-400 dark:border-white/20">
              <i className="fa-solid fa-plus"></i>Head
            </a>

            <div className={`scrollbar-thumb-slate-400 pr-3 flex-col flex-1 overflow-y-auto border-b dark:border-white/20 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md`}>
              <div className="flex flex-col gap-2  text-sm ">

                <ul className="space-y-2">
                  <li>
                    <Link
                      onClick={() => setcurrentTab("accounts")}
                      to="?tab=accounts"
                      className={`${currentTab === "accounts" && `bg-gray-700`} flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <i className="fa-solid fa-users flex-shrink-0"></i>
                      <span className="flex-1 ml-3 whitespace-nowrap">Accounts</span>
                      <span title="Current online users" className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-green-600 bg-gray-200 rounded-full dark:bg-gray-600">
                        {Number(onlineUsers?.length).toLocaleString()}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setcurrentTab("rooms")}
                      to="?tab=rooms"
                      className={`${currentTab === "rooms" && `bg-gray-700`} flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <i className="fa-solid fa-comments flex-shrink-0"></i>
                      <span className="flex-1 ml-3 whitespace-nowrap">Rooms</span>
                      {/*<span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span>*/}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setcurrentTab("logs")}
                      to="?tab=logs"
                      className={`${currentTab === "logs" && `bg-gray-700`} flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <i className="fa-solid fa-file-pen flex-shrink-0"></i>
                      <span className="flex-1 ml-3 whitespace-nowrap">Logs</span>
                      {/*<span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>*/}
                    </Link>
                  </li>
                </ul>

              </div>
            </div>

            <a onClick={() => logout()} className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-700 transition-colors duration-200 text-gray-600 dark:text-white hover:text-white cursor-pointer text-sm">
              <i className="fa-solid fa-right-from-bracket"></i>
              Log out
            </a>

          </nav>
        </div>
      </div >
    </div >
  );
};

export default LeftSide;