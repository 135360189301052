import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Accounts from "./admin/Accounts";
import Header from "./admin/Header";
import LeftSide from "./admin/LeftSide";
import Logs from "./admin/Logs";
import Rooms from "./admin/Rooms";
import { toastEffect } from "./toast";
import AccountModal from "./admin/AccountModal";

const AdminPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentTab = params.get("tab");

  const [selectedOption, setSelectedOption] = useState("");
  const [currentTheme, setCurrentTheme] = useState("");
  const [_currentTab, setcurrentTab] = useState("");
  const [allAccounts, setAllAccounts] = useState([]);
  const [allDeletedRooms, setAllDeletedRooms] = useState([]);
  const [allLogs, setAllLogs] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [refreshAccount, setRefreshAccount] = useState(0);
  const [publicRoomSessionID, setPublicRoomSessionID] = useState("");
  const [accountModalShow, setAccountModalShow] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [avatars, setAvatars] = useState([]);
  //console.log(accountID);

  const email = localStorage.getItem("email");

  /**Dark mode function */
  useEffect(() => {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");
      setCurrentTheme(localStorage.theme = "dark");
    } else {
      document.documentElement.classList.remove("dark");
      setCurrentTheme(localStorage.theme = "light");
    }
  }, []);

  const handleTheme = () => {
    if (localStorage.theme === "dark") {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    } else {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
    }
    setCurrentTheme(localStorage.theme);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    const getAllAdminData = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}getAllAccounts`)
          .then((result) => {
            setAllAccounts(result.data);
          });
        await axios.post(`${process.env.REACT_APP_API_URL}getAllDeletedRooms`, {
          email: email
        }).then((result) => {
          setAllDeletedRooms(result.data);
        });
        await axios.post(`${process.env.REACT_APP_API_URL}getAllLogs`)
          .then((result) => {
            setAllLogs(result.data);
          });
        await axios.post(`${process.env.REACT_APP_API_URL}getConfigs`)
          .then((result) => {
            setConfigs(result.data);
          });
        await axios.post(`${process.env.REACT_APP_API_URL}getOnlineUsers`)
          .then((res) => {
            setOnlineUsers(res.data);
          });
      } catch (err) {
        toastEffect("error", "Error! #101921");
        //console.log(err);
      }
    };
    getAllAdminData();
  }, [refreshAccount]);

  async function handleAccountStatus(e, id, email, name) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}updateAccountStatus`, {
        accountID: id,
        value: e.target.checked,
        email: email,
        name: name
      }).then(() => {
        setRefreshAccount(oldval => oldval + 1);
      });
    } catch (err) {
      if (err.response.status === 400) {
        toastEffect("error", err.response.data.msg);
      } else {
        toastEffect("error", "Error! #101922");
        //console.log(err);
      }
    }
  };

  async function handleRoomStatus(e, id) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}updateRoomStatus`, {
        accountID: id,
        value: roomStatus(e.target.checked)
      }).then(() => {
        setRefreshAccount(oldval => oldval + 1);
      });
    } catch (err) {
      toastEffect("error", "Error! #101923");
      //console.log(err);
    }
  }

  function roomStatus(dataStatus) {
    if (dataStatus) {
      return false;
    } else {
      return true;
    }
  }

  async function handleSavePublicRoomSessionID() {
    if (publicRoomSessionID.trim().length === 0 || publicRoomSessionID === "") {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}updatePublicRoom`, {
        value: publicRoomSessionID
      }).then((result) => {
        //setRefreshAccount(oldval => oldval + 1);
        toastEffect("success", "Saved successfully");
      });
    } catch (err) {
      if (err.response.status === 400) {
        toastEffect("error", err.response.data.msg);
      } else {
        toastEffect("error", "Error! #101924");
        //console.log(err);
      }
    }
  }

  async function toggleEdit(accountData) {
    if (accountModalShow) {
      setAccountModalShow(false);
    } else {
      setAccountModalShow(true);
    }
    setAccountData(accountData);
    setSelectedOption(accountData.accountType);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}getAvatars`, {
        email: accountData.accountEmail
      }).then((result) => {
        setAvatars(result.data);
      });
    } catch (err) {
      toastEffect("error", "Error! #101925");
      //console.log(err);
    }
  }

  function accountPage() {
    return (
      <Accounts
        toggleEdit={toggleEdit}
        onlineUsers={onlineUsers}
        configs={configs}
        email={email}
        allAccounts={allAccounts}
        handleAccountStatus={handleAccountStatus}
        setPublicRoomSessionID={setPublicRoomSessionID}
        handleSavePublicRoomSessionID={handleSavePublicRoomSessionID}
      />
    );
  }

  return (
    <div className="h-screen w-full antialiased text-gray-200 bg-gray-50 dark:bg-gray-900 overflow-hidden">

      <Header
        currentTheme={currentTheme}
        handleTheme={handleTheme}
      />
      <LeftSide
        onlineUsers={onlineUsers}
        setcurrentTab={setcurrentTab}
        currentTab={currentTab}
        _currentTab={_currentTab}
        logout={logout}
      />
      {_currentTab === "accounts" || currentTab === "accounts" ? (
        accountPage()
      ) : _currentTab === "rooms" || currentTab === "rooms" ? (
        <Rooms
          roomStatus={roomStatus}
          handleRoomStatus={handleRoomStatus}
          allDeletedRooms={allDeletedRooms}
        />
      ) : _currentTab === "logs" || currentTab === "logs" ? (
        <Logs
          allLogs={allLogs}
        />
      ) : (
        accountPage()
      )}

      {accountModalShow && (
        <AccountModal
          selectedOption={selectedOption}
          avatars={avatars}
          accountData={accountData}
          setSelectedOption={setSelectedOption}
          setAvatars={setAvatars}
          setRefreshAccount={setRefreshAccount}
          setAccountModalShow={setAccountModalShow}
        />
      )}
    </div>
  );
};

export default AdminPage;