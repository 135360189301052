import { useState } from "react";
import axios from "axios";
import { toastEffect } from "./toast";

const ModalAvatar = (props) => {

  const {
    setOpenIMGModal,
    sessionData,
    avatars,
    setAvatars,
    setRefreshConvo,
    refetch,
    chatSessionFtSession
  } = props;

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {

    if (file === null) {
      return;
    }
    if (!sessionData[0]?.email) {
      return;
    }
    if (!chatSessionFtSession[0]?.session) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    let details = {
      email: sessionData[0]?.email
    };
    for (let key in details) {
      formData.append(key, details[key]);
    }
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }, onUploadProgress: ProgressEvent => {
        setProgress(parseInt(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)));
        setTimeout(() => setProgress(0), 2500);
      }
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}upload_avatar`, formData, config)
        .then((result) => {
          setFile(null);
          //console.log(result.data);
          //setRefreshConvo(val => val + 1);
          setAvatars([...avatars, { avatar: result.data, email: sessionData[0]?.email }]);
          toastEffect("success", "Uploaded successfully");
        });
      //const { fileName, filePath } = res.data;
      //setuploadedFile({ fileName, filePath });
    } catch (err) {
      if (err.response.status === 500) {
        toastEffect("error", "There was a problem with the server");
      } else if (err.response.status === 400) {
        toastEffect("error", err.response.data.msg);
      } else {
        toastEffect("error", "Error! #101933");
      }
    }
  };

  async function handleDeleteAvatar(id, imageName) {
    if (!id || !imageName) {
      return;
    }

    setLoadingAvatar(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}deleteImage`, {
        dataID: id,
        imageName: imageName
      }).then(() => {
        const newAvatars = avatars.filter((avatar) => avatar.avatar !== imageName);
        setAvatars(newAvatars);
        setLoadingAvatar(false);
        toastEffect("success", "Deleted successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101934");
      //console.log(err);
    }
  }

  async function handleSetAvatar(email, imageName) {
    if (!email || !imageName) {
      return;
    }

    setLoadingAvatar(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}setAvatar`, {
        email: email,
        imageName: imageName
      }).then(() => {
        refetch();
        setLoadingAvatar(false);
        toastEffect("success", "Saved successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101935");
      //console.log(err);
    }
  }

  return (
    <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="h-modal overflow-auto fixed top-0 left-0 right-0 z-50 w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0 md:h-full">
      <div className="relative h-full w-full max-w-2xl md:h-auto">

        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">

          <div className="flex items-start justify-between rounded-t border-b p-4 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Avatar Images</h3>
            <button onClick={() => setOpenIMGModal(false)} type="button" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
              <i className="fa-solid fa-xmark" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="container mx-auto px-3 modal-content">
            <section className="py-8 px-4">
              <div className="-mx-4 -mb-8 flex flex-wrap justify-center">

                {avatars.map((data, idx) => {
                  return (
                    <div key={idx} className="mb-1 px-0 mr-1 md:w-1/4 relative">
                      <img className="rounded shadow-md w-full h-full object-cover object-center hover:scale-105" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.avatar}`} alt="img" />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-80 bg-gray-600 rounded">
                        {loadingAvatar ? (
                          <i className="fa-solid fa-spinner fa-spin-pulse" />
                        ) : (

                          sessionData[0]?.avatar === data.avatar ? (
                            <i className="fa-solid fa-circle-check text-green-500" />
                          ) : (
                            <>
                              <i onClick={() => handleSetAvatar(data.email, data.avatar)} title="Set avatar" className="fa-solid fa-check-double mr-1 cursor-pointer text-green-500 hover:text-green-600" />
                              <i onClick={() => handleDeleteAvatar(data.id, data.avatar)} title="Delete Image" className="fa-solid fa-trash ml-1 cursor-pointer text-red-500 hover:text-red-600" />
                            </>
                          )

                        )}

                      </div>
                    </div>
                  );
                })}

              </div>
            </section>
          </div>

          <div className="relative flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
            <div id="progress-bar-container" className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              {progress !== 0 && (
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${progress}%` }}> {progress}%</div>
              )}
            </div>
            <input onChange={handleFileChange} type="file" className="px-5 py-2.5 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
            <button onClick={handleUpload} data-modal-hide="defaultModal" type="button" className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAvatar;