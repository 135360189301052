import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toastEffect } from "./toast";

const ModalLogo = (props) => {

  const {
    setOpenLogoModal,
    sessionData,
    logos,
    setLogos,
    setRefreshConvo,
    refetch,
    chatSessionFtSession,
    setRefreshImages,
    sessionID,
    setSession,
    session
  } = props;

  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loadingLogo, setLoadingLogo] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {

    if (file === null) {
      return;
    }
    if (!chatSessionFtSession[0]?.session) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    let details = {
      email: sessionData[0]?.email,
      session: chatSessionFtSession[0]?.session
    };
    for (let key in details) {
      formData.append(key, details[key]);
    }
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }, onUploadProgress: ProgressEvent => {
        setProgress(parseInt(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)));
        setTimeout(() => setProgress(0), 2500);
      }
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}upload_logo`, formData, config)
        .then((result) => {
          setFile(null);
          //console.log(result.data);
          //setRefreshImages(val => val + 1);
          //navigate(window.location.pathname);
          setLogos([...logos, { logo: result.data, session: chatSessionFtSession[0]?.session, uploader: sessionData[0]?.email }]);
          toastEffect("success", "Uploaded successfully");
        });
    } catch (err) {
      if (err?.response?.status === 500) {
        toastEffect("error", "There was a problem with the server");
      } else if (err?.response?.status === 400) {
        toastEffect("error", err?.response?.data?.msg);
      } else {
        toastEffect("error", "Error! #101936");
      }
    }
  };

  async function handleDeleteLogo(id, imageName) {
    if (!id || !imageName) {
      return;
    }

    setLoadingLogo(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}deleteLogo`, {
        dataID: id,
        imageName: imageName
      }).then(() => {
        //setRefreshImages(val => val + 1);
        const newLogos = logos.filter((logo) => logo.id !== id);
        setLogos(newLogos);
        setLoadingLogo(false);
        toastEffect("success", "Deleted successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101937");
    }
  }

  async function handleSetLogo(email, imageName) {
    if (!email || !imageName) {
      return;
    }

    setLoadingLogo(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}setLogo`, {
        email: email,
        imageName: imageName,
        session: chatSessionFtSession[0]?.session
      }).then(() => {
        refetch();
        //setRefreshConvo(val => val + 1);
        setSession(session.map((logo) => {
          return { ...logo, logo: imageName };
        }));
        setLoadingLogo(false);
        toastEffect("success", "Saved successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101938");
    }
  }

  return (
    <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="h-modal overflow-auto fixed top-0 left-0 right-0 z-50 w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0 md:h-full">
      <div className="relative h-full w-full max-w-2xl md:h-auto">

        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">

          <div className="flex items-start justify-between rounded-t border-b p-4 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Logo Images</h3>
            <button onClick={() => setOpenLogoModal(false)} type="button" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
              <i className="fa-solid fa-xmark" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="container mx-auto px-3 modal-content">
            <section className="py-8 px-4">
              <div className="-mx-4 -mb-8 flex flex-wrap justify-center">

                {logos.map((data, idx) => {
                  return (
                    <div key={idx} className="mb-1 px-0 mr-1 md:w-1/4 relative">
                      <img className="rounded shadow-md w-full h-full object-cover object-center hover:scale-105" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.logo}`} alt="img" />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-80 bg-gray-600 rounded">
                        {loadingLogo ? (
                          <i className="fa-solid fa-spinner fa-spin-pulse" />
                        ) : (

                          chatSessionFtSession[0]?.logo === data.logo ? (
                            <i className="fa-solid fa-circle-check text-green-500" />
                          ) : (
                            <>
                              <i onClick={() => handleSetLogo(data.uploader, data.logo)} title="Set avatar" className="fa-solid fa-check-double mr-1 cursor-pointer text-green-500 hover:text-green-600" />
                              <i onClick={() => handleDeleteLogo(data.id, data.logo)} title="Delete Image" className="fa-solid fa-trash ml-1 cursor-pointer text-red-500 hover:text-red-600" />
                            </>
                          )

                        )}
                      </div>
                    </div>
                  );
                })}

              </div>
            </section>
          </div>

          <div className="relative flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
            <div id="progress-bar-container" className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              {progress !== 0 && (
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${progress}%` }}> {progress}%</div>
              )}
            </div>
            <input onChange={handleFileChange} type="file" className="px-5 py-2.5 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
            <button onClick={handleUpload} data-modal-hide="defaultModal" type="button" className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogo;