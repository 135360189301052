import { useState } from "react";
import moment from 'moment';
import axios from "axios";
import getTimeDiff from "./TimeDiff";
import Swal from "sweetalert2";
import { toastEffect } from "./toast";

const RightSide = (props) => {

  const {
    sessionID,
    theSessionID,
    session,
    setSession,
    chatSession,
    email,
    onlineUsers,
    convo,
    chatSessionFtSession,
    allUsers,
    refetch,
    sessionData,
    socket,
    setOpenIMGModal,
    openIMGModal,
    setOpenLogoModal,
    openLogoModal
  } = props;
  //console.log(session);
  moment.locale('en');

  const peopleLocalStorage = localStorage.getItem("people");
  const photosLocalStorage = localStorage.getItem("photos");
  const optionLocalStorage = localStorage.getItem("option");

  const [isAdding, setIsAdding] = useState(false);
  const [optiontoggle, setOptiontoggle] = useState(optionLocalStorage ? JSON.parse(optionLocalStorage) : false);
  const [peopletoggle, setPeopletoggle] = useState(peopleLocalStorage ? JSON.parse(peopleLocalStorage) : false);
  const [isMediaVisible, setIsMediaVisible] = useState(photosLocalStorage ? JSON.parse(photosLocalStorage) : false);
  const [userSearch, setUserSearch] = useState("");

  function handleFocus() {
    setIsAdding(true);
  }

  function handleOnBlur() {
    setIsAdding(false);
  }

  function handleOption() {
    if (optiontoggle) {
      setOptiontoggle(false);
      localStorage.setItem("option", false);
    } else {
      setOptiontoggle(true);
      localStorage.setItem("option", true);
    }
  }

  function handlePeopetoggle() {
    if (peopletoggle) {
      setPeopletoggle(false);
      localStorage.setItem("people", false);
    } else {
      setPeopletoggle(true);
      localStorage.setItem("people", true);
    }
  }

  function toggleMediaVisibility() {
    if (isMediaVisible) {
      setIsMediaVisible(false);
      localStorage.setItem("photos", false);
    } else {
      setIsMediaVisible(true);
      localStorage.setItem("photos", true);
    }
  }

  async function handleRemove(dataID, email) {
    if (!dataID) {
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}removeMember`, {
        dataID: dataID
      }).then(async () => {
        await socket.emit("removeMember", {
          email: email
        });
        refetch();
      });
    } catch (err) {
      toastEffect("error", "Error! #101939");
    }
  }

  function excludedArray() {
    const excludedArray = allUsers.filter((obj1) =>
      !chatSessionFtSession[0]?._session?.members?.some((obj2) => obj1.email === obj2.email)
    );
    return excludedArray;
  }

  //console.log("ThisDATA", excludedArray);

  const filteredUserSearch = excludedArray().filter((el) => {
    if (userSearch === '') {
      return el;
    } else {
      return el.email.toLowerCase().includes(userSearch.toLowerCase()) ||
        el.first_name.toLowerCase().includes(userSearch.toLowerCase()) ||
        el.last_name.toLowerCase().includes(userSearch.toLowerCase());
    }
  });

  async function handleAdd(email, name) {
    if (!email || !chatSessionFtSession[0]?.session) {
      return;
    }
    //console.log(email);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}addMember`, {
        email: email,
        name: name,
        session: chatSessionFtSession[0]?.session,
        title: chatSessionFtSession[0]?.title,
        logo: chatSessionFtSession[0]?.logo
      }).then(async () => {
        await socket.emit("addMember", {
          email: email
        });
        refetch();
      });
    } catch (err) {
      toastEffect("error", "Error! #101940");
    }
  }

  function toggleUserAvatarModal() {
    if (openIMGModal) {
      setOpenIMGModal(false);
    } else {
      setOpenIMGModal(true);
    }
  }

  function toggleRoomLogoModal() {
    if (openLogoModal) {
      setOpenLogoModal(false);
    } else {
      setOpenLogoModal(true);
    }
  }

  function handleDeleteRoom(session) {
    if (!chatSessionFtSession[0]?.session) {
      return;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You can revert this on the admin page!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      customClass: "bg-gray-300 dark:bg-gray-800 text-gray-800 dark:text-gray-300"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${process.env.REACT_APP_API_URL}removeRoom`, {
            session: session
          }).then(async () => {
            await socket.emit("removeRoom", {
              session: session
            });
            refetch();
            toastEffect("success", "Room has been deleted successfully.");
          });
        } catch (err) {
          toastEffect("error", "Error! #101941");
        }
      }
    });
  }

  return (
    <section className="flex flex-col flex-none overflow-auto w-24 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out border-l border-gray-300 dark:border-gray-800">

      <div className="header p-4 flex flex-row justify-between items-center flex-none">
        <div className="text-md font-bold hidden md:block group-hover:block w-full text-center">
          <div className="flex flex-col items-center text-sm">
            <div className="p-1 border-4 border-blue-600 rounded-full">
              <div className="w-16 h-16 relative flex flex-shrink-0">
                <img
                  className="shadow-md rounded-full w-full h-full object-cover"
                  src={session[0]?.logo ? `${process.env.REACT_APP_UPLOAD_LOCATION}${session[0]?.logo}` : `${process.env.REACT_APP_UPLOAD_LOCATION}chat_logo.png`} alt="chat room" />
              </div>
            </div>
            <p title={session[0]?.title} className="truncate w-80 mt-2 text-gray-700 dark:text-gray-200">{session[0]?.title}</p>
            <small className="text-gray-400 dark:text-gray-600">
              {convo.slice().reverse()[0]?.created ? `Active ${getTimeDiff(convo.slice().reverse()[0]?.created)}` : "New Room"}
            </small>
          </div>
        </div>
      </div>

      <div className="contacts p-2 flex-1 overflow-y-scroll">
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-900">
          {/*<ul className="space-y-2">
            <li>
              <button type="button" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <i className="fa-solid fa-users"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true">People</span>
                <i className="fa-solid fa-chevron-right"></i>
              </button>
              <ul id="dropdown-example" className="hidden py-2 space-y-2">
                <li>
                  <a href="#" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Products</a>
                </li>
                <li>
                  <a href="#" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Billing</a>
                </li>
                <li>
                  <a href="#" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Invoice</a>
                </li>
              </ul>
            </li>
          </ul>*/}

          <ul className="space-y-2">
            <li>
              <button onClick={handlePeopetoggle} type="button" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <i className="fa-solid fa-users"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true">People</span>
                <i className={peopletoggle ? "fa-solid fa-angle-down" : "fa-solid fa-chevron-right"}></i>
              </button>

              {(theSessionID || sessionID) && chatSession[0]?.session && chatSessionFtSession[0]?.session && (
                <ul id="dropdown-example" className={`${!peopletoggle && "hidden"} py-2 space-y-2`}>

                  {/*<li>
                  <a href="#" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                    <i className="fa-solid fa-trash"></i>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">Delete</span>
                  </a>
                </li>*/}
                  <li>
                    {chatSessionFtSession[0]?._session?.members.map((data, idx) => {
                      //const onlineUser = onlineUsers?.map((data) => { return (data); });
                      const onlineUser = onlineUsers.find((online) => online.email === data.email);
                      function isOnline() {
                        if (onlineUser || email === data.email) {
                          return (
                            <div className="absolute bg-gray-100 dark:bg-gray-900 p-1 rounded-full bottom-0 right-0">
                              <div className="bg-green-500 rounded-full w-3 h-3"></div>
                            </div>
                          );
                        }
                      }
                      return (
                        <div key={idx} className="flex justify-between items-center p-3 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg rounded-lg relative">
                          <div className="w-16 h-16 relative flex flex-shrink-0">
                            <img className="shadow-md rounded-full w-full h-full object-cover bg-gray-200 dark:bg-gray-800"
                              src={data.avatar ? `${process.env.REACT_APP_UPLOAD_LOCATION}${data.avatar}` : `${process.env.REACT_APP_UPLOAD_LOCATION}profile_default.png`}
                              alt="avatar"
                            />
                            {/**online dot here */}
                            {isOnline()}
                          </div>
                          <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
                            <p className={sessionData[0]?.type === "admin" ? "cursor-help" : ""} title={sessionData[0]?.type === "admin" ? data.email : ""}>{data.alias}</p>
                            <div className="flex items-center text-sm text-gray-600">
                              <div className="w-[170px]">
                                <p title={moment(data.created).format('MMMM Do YYYY, h:mm:ss a')} className="truncate">{moment(data.created).format('MMMM Do YYYY, h:mm:ss a')}</p>
                              </div>
                              <p className="ml-2 whitespace-no-wrap"></p>
                            </div>
                          </div>
                          {data.email !== sessionData[0]?.email && sessionData[0]?.type === "admin" && (
                            <div onClick={() => handleRemove(data.id, data.email)} className="cursor-pointer w-4 h-4 flex flex-shrink-0 hidden md:block group-hover:block hover:text-red-500">
                              <i className="fa-solid fa-trash fa-lg" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </li>

                  {sessionData[0]?.type === "admin" && (
                    <li>
                      {/**input add users */}
                      <div className="relative mb-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="fa-solid fa-users"></i>
                        </div>
                        <input
                          onFocus={handleFocus}
                          onChange={(e) => setUserSearch(e.target.value)}
                          placeholder="Type username"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                      </div>

                      {/**Card for users*/}
                      <a className={`${!isAdding && "hidden"} block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>

                        {filteredUserSearch.map((data, idx) => {
                          const onlineUser = onlineUsers.find((online) => online.email === data.email);
                          function isOnline() {
                            if (onlineUser || email === data.email) {
                              return (
                                <div className="absolute bg-gray-100 dark:bg-gray-900 p-1 rounded-full bottom-0 right-0">
                                  <div className="bg-green-500 rounded-full w-3 h-3"></div>
                                </div>
                              );
                            }
                          }
                          return (
                            <div onClick={() => handleAdd(data.email, data.alias)} key={idx} className="cursor-pointer flex justify-between items-center p-3 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg rounded-lg relative">
                              <div className="w-16 h-16 relative flex flex-shrink-0">
                                <img className="shadow-md rounded-full w-full h-full object-cover bg-gray-200 dark:bg-gray-600"
                                  src={data.avatar ? `${process.env.REACT_APP_UPLOAD_LOCATION}${data.avatar}` : `${process.env.REACT_APP_UPLOAD_LOCATION}profile_default.png`}
                                  alt="avatar"
                                />
                                {isOnline()}
                              </div>
                              <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
                                {/*<p>{data.first_name} {data.last_name}</p>*/}
                                <p className={sessionData[0]?.type === "admin" ? "cursor-help" : ""} title={sessionData[0]?.type === "admin" ? data.email : ""}>{data.alias}</p>
                                <div className="flex items-center text-sm text-gray-600">
                                  <div className="w-[170px]">
                                    <p title={moment(data.created).format('MMMM Do YYYY, h:mm:ss a')} className="truncate">{moment(data.created).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    {/*<p title={data.email} className="truncate">{data.email}</p>*/}
                                  </div>
                                  <p className="ml-2 whitespace-no-wrap"></p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <button onClick={handleOnBlur} type="button" className="px-3 py-2 w-full mt-1 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          Close
                        </button>
                      </a>

                    </li>
                  )}


                </ul>
              )}
            </li>
          </ul>

          <ul className="space-y-2">
            <li>
              <button onClick={toggleMediaVisibility} type="button" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <i className="fa-solid fa-image"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true">Photos</span>
                <i className={isMediaVisible ? "fa-solid fa-angle-down" : "fa-solid fa-chevron-right"}></i>
              </button>

              {isMediaVisible && (theSessionID || sessionID) && chatSession[0]?.session && chatSessionFtSession[0]?.session && (
                <ul id="dropdown-example" className="py-2 space-y-2">
                  <section className="py-8 px-4">
                    <div className="-mx-4 -mb-8 flex flex-wrap justify-center">

                      {/*<div className="mb-1 px-0 mr-1 md:w-1/4 relative">
                        <img className="rounded shadow-md w-full h-full" src="/uploads/1677071312.png" alt="img" />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-80 bg-gray-600 rounded">
                          <i className="fa-solid fa-circle-check text-green-500" />
                        </div>
                      </div>*/}

                      {convo.map((data, idx) => {
                        if (data.type === "img") {
                          return (
                            <div key={idx} className="mb-1 px-0 mr-1 md:w-1/4 relative">
                              <img className="rounded shadow-md w-full h-full object-cover object-center hover:scale-105" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.message}`} alt="img" />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </section>
                </ul>
              )}
            </li>
          </ul>


          <ul className="space-y-2">
            <li>
              <button onClick={handleOption} type="button" className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <i className="fa-solid fa-list-check" />
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true">Options</span>
                <i className={optiontoggle ? "fa-solid fa-angle-down" : "fa-solid fa-chevron-right"}></i>
              </button>
              {(theSessionID || sessionID) && chatSession[0]?.session && chatSessionFtSession[0]?.session && (
                <ul id="dropdown-example" className={`py-2 space-y-2 ${!optiontoggle && "hidden"}`}>

                  {chatSessionFtSession[0]?.session && (
                    <li>
                      <a onClick={toggleUserAvatarModal} className="cursor-pointer flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                        <i className="fa-solid fa-circle-user" />
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">User Avatar</span>
                      </a>
                    </li>
                  )}

                  {sessionData[0]?.type === "admin" && (
                    <>
                      <li>
                        <a onClick={toggleRoomLogoModal} className="cursor-pointer flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                          <i className="fa-regular fa-comments" />
                          <span className="flex-1 ml-3 text-left whitespace-nowrap">Room Logo</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => handleDeleteRoom(chatSessionFtSession[0]?.session)} className="cursor-pointer flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                          <i className="fa-solid fa-trash" />
                          <span className="flex-1 ml-3 text-left whitespace-nowrap">Delete Room</span>
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </li>
          </ul>


        </div>
      </div >
    </section >
  );
};

export default RightSide;