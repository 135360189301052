import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import checkSession from "../hooks/checkSession";

const Header = () => {

  const navigate = useNavigate();

  const [currentTheme, setCurrentTheme] = useState("");

  const token = JSON.parse(localStorage.getItem("user"));
  const email = localStorage.getItem("email");

  const { data: sessionData, loading: sessionLoading, error: sessionError } = checkSession(token?.accessToken);

  /**Dark mode function */
  useEffect(() => {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");
      setCurrentTheme(localStorage.theme = "dark");
    } else {
      document.documentElement.classList.remove("dark");
      setCurrentTheme(localStorage.theme = "light");
    }
  }, []);

  const handleTheme = () => {
    if (localStorage.theme === "dark") {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    } else {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
    }
    setCurrentTheme(localStorage.theme);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    navigate("/");
    window.location.reload();
  };

  return (
    <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <Link to="/" className="flex items-center">
          <img src="/family-law_logo192.png" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white text-gray-800">Mothers and the Family Court</span>
        </Link>
        <div className="flex items-center lg:order-2">


          {sessionData[0]?.type === "admin" && (
            <Link to="/adminpage" title="Admin page" className="cursor-pointer text-gray-800 dark:text-white hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
              <i className="fa-solid fa-user-secret fa-lg"></i>
            </Link>
          )}

          {!!sessionData.length && (
            <>
              <Link to="/chats" title="Chat page" className="cursor-pointer text-gray-800 dark:text-white hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
                <i className="fa-solid fa-comments fa-lg"></i>
              </Link>
              <a onClick={logout} title="Log out" className="cursor-pointer text-gray-800 dark:text-white hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
                <i className="fa-solid fa-right-from-bracket fa-lg"></i>
              </a>
            </>
          )}

          <a onClick={handleTheme} title={currentTheme === "dark" ? "Light Mode" : "Dark Mode"} className="cursor-pointer text-gray-800 dark:text-white hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
            <i className={currentTheme === "dark" ? "fa-solid fa-sun fa-lg" : "fa-solid fa-moon fa-lg"}></i>
          </a>

        </div>
      </div>
    </nav>
  );
};

export default Header;