import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import getTimeDiff from "./TimeDiff";
import { toastEffect } from "./toast";

const LeftSide = (props) => {

  const {
    chatSession,
    handleSession,
    theSessionID,
    sessionID,
    sessionData,
    refetch,
    socket
  } = props;

  moment.locale('en');
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  const [addSession, setAddSession] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  //new chat update realtime
  useEffect(() => {
    const updateNewChat = () => {
      socket?.on("addMember", data => {
        if (data) {
          refetch();
        }
      });
    };
    updateNewChat();
  }, [socket]);

  useEffect(() => {
    const updateNewChat = () => {
      socket?.on("removeMember", data => {
        if (data) {
          refetch();
        }
      });
    };
    updateNewChat();
  }, [socket]);

  useEffect(() => {
    const updateNewChat = () => {
      socket?.on("removeRoom", data => {
        if (data) {
          refetch();
        }
      });
    };
    updateNewChat();
  }, [socket]);

  const sortedArray = chatSession.slice().sort((a, b) => {
    const dateA = new Date(a.convo[0]?.created);
    const dateB = new Date(b.convo[0]?.created);
    return dateB - dateA;
  });

  async function handleAdd() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (sessionName === "") {
      return;
    }
    setIsCreating(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}createSession`, {
        email: sessionData[0]?.email,
        name: sessionData[0]?.alias,
        session: sessionName
      }).then((result) => {
        setAddSession(false);
        setIsCreating(false);
        setSessionName("");
        refetch();
      });
    } catch (err) {
      toastEffect("error", "Error! #101930");
      //console.log(err);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" && sessionData[0]?.type === "admin" && sessionName && addSession) {
      handleAdd();
      setSessionName("");
    }
  }

  function emptyFunction() { }

  function toggleAdd() {
    if (addSession) {
      setAddSession(false);
    } else {
      setAddSession(true);
    }
  }

  const filteredChatRoomSearch = sortedArray.filter((el) => {
    if (addSession) {
      return el;
    } else {
      if (sessionName === '') {
        return el;
      } else {
        return el.title.toLowerCase().includes(sessionName.toLowerCase());
      }
    }
  });

  //console.log(filteredChatRoomSearch);

  return (
    <>
      {/*<section className="flex flex-col flex-none overflow-auto w-24 hover:w-64 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out">*/}
      <section className="flex flex-col flex-none overflow-auto w-24 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out">

        <div className="header p-4 flex flex-row justify-between items-center flex-none">

          {/*<div onClick={handleImageClick} className="cursor-pointer w-16 h-16 relative flex flex-shrink-0">
            <img className="opacity-60 bg-gray-200 dark:bg-gray-800 rounded-full w-full h-full object-cover transition duration-300 hover:opacity-50" src="/uploads/profile_default.png" alt="avatar" />
            <input onChange={handleFileChange} className="hidden" ref={fileInputRef} type="file" name="fileInput" accept="image/*" multiple />
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 hover:opacity-60 bg-gray-600 rounded-full">
              <i className="fa-solid fa-image" />
            </div>
          </div>*/}

          <div className="w-16 h-16 relative flex flex-shrink-0 bg-gray-200 dark:bg-gray-800 rounded-full" >
            <img
              className="rounded-full w-full h-full object-cover"
              src={sessionData[0]?.avatar ? `${process.env.REACT_APP_UPLOAD_LOCATION}${sessionData[0]?.avatar}` : `${process.env.REACT_APP_UPLOAD_LOCATION}profile_default.png`}
              alt="avatar"
            />
          </div>

          <div className="text-md font-bold hidden md:block group-hover:block">
            <div className="search-box p-4 flex-none">
              <div className="relative">
                <label>
                  <input
                    onChange={(e) => setSessionName(e.target.value)}
                    onKeyDown={handleKeyPress}
                    value={sessionName}
                    ref={inputRef}
                    placeholder={addSession ? "Session name" : "Type room name"}
                    type="text"
                    disabled={isCreating}
                    className="rounded-full py-2 pr-6 pl-10 w-full border border-gray-300 dark:border-gray-800 dark:focus:border-gray-700 dark:bg-gray-800 dark:focus:bg-gray-900 focus:outline-none text-gray-600 dark:text-gray-200 focus:shadow-md transition duration-300 ease-in" />
                  <span className="absolute top-0 left-0 mt-2 ml-3 inline-block text-gray-700 dark:text-gray-200">
                    <i className={addSession ? "fa-solid fa-comments" : isCreating ? "fa-solid fa-spinner fa-spin-pulse fa-spin-reverse" : "fa-solid fa-magnifying-glass"} />
                  </span>
                </label>
              </div>
            </div>
          </div>
          {sessionData[0]?.type === "admin" && (
            <a
              onClick={addSession && sessionName ? handleAdd : toggleAdd}
              className="cursor-pointer rounded-full text-gray-600 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:bg-gray-800 w-10 h-10 p-2 hidden md:inline-flex md:items-center group-hover:block"
            >
              <span className="fill-blue-500">
                {addSession && sessionName ? (
                  <svg className="h-6 w-6" viewBox="-55 -25 612 612">
                    <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                  </svg>
                ) : !sessionName && addSession ? (
                  <svg onClick={toggleAdd} className="h-6 w-6" viewBox="-55 -25 612 612">
                    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" viewBox="0 0 24 24">
                    <path d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z" />
                  </svg>
                )}
              </span>
            </a>
          )}
        </div>

        {/*<div className="search-box p-4 flex-none">
          <form>
            <div className="relative">
              <label>
                <input className="rounded-full py-2 pr-6 pl-10 w-full border border-gray-800 focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                  type="text" defaultValue="" placeholder="Search Messenger" />
                <span className="absolute top-0 left-0 mt-2 ml-3 inline-block">
                  <svg viewBox="0 0 24 24" className="w-6 h-6">
                    <path fill="#bbb"
                      d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                  </svg>
                </span>
              </label>
            </div>
          </form>
        </div>
        <div className="active-users flex flex-row p-2 overflow-auto w-0 min-w-full">
          <div className="text-sm text-center mr-4">
            <button className="flex flex-shrink-0 focus:outline-none block bg-gray-800 text-gray-600 rounded-full w-20 h-20"
              type="button">
              <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
                <path d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z" />
              </svg>
            </button>
            <p>Your Story</p>
          </div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-blue-600 rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/women/12.jpg"
              alt=""
            />
          </div></div><p>Anna</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-transparent rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/men/75.jpg"
              alt=""
            />
            <div className="absolute bg-gray-900 p-1 rounded-full bottom-0 right-0">
              <div className="bg-green-500 rounded-full w-3 h-3"></div>
            </div>
          </div></div><p>Jeff</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-blue-600 rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/women/42.jpg"
              alt=""
            />
          </div></div><p>Cathy</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-transparent rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/women/87.jpg"
              alt=""
            />
            <div className="absolute bg-gray-900 p-1 rounded-full bottom-0 right-0">
              <div className="bg-green-500 rounded-full w-3 h-3"></div>
            </div>
          </div></div><p>Madona</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-transparent rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/women/23.jpg"
              alt=""
            />
            <div className="absolute bg-gray-900 p-1 rounded-full bottom-0 right-0">
              <div className="bg-green-500 rounded-full w-3 h-3"></div>
            </div>
          </div></div><p>Emma</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-blue-600 rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/men/65.jpg"
              alt=""
            />
          </div></div><p>Mark</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-blue-600 rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/women/65.jpg"
              alt=""
            />
          </div></div><p>Eva</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-transparent rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/men/31.jpg"
              alt=""
            />
            <div className="absolute bg-gray-900 p-1 rounded-full bottom-0 right-0">
              <div className="bg-green-500 rounded-full w-3 h-3"></div>
            </div>
          </div></div><p>Max</p></div>
          <div className="text-sm text-center mr-4"><div className="p-1 border-4 border-blue-600 rounded-full"><div className="w-16 h-16 relative flex flex-shrink-0">
            <img className="shadow-md rounded-full w-full h-full object-cover"
              src="https://randomuser.me/api/portraits/men/81.jpg"
              alt=""
            />
          </div></div><p>Adam</p></div>
        </div>*/}

        <div className="contacts p-2 flex-1 overflow-y-scroll">

          {filteredChatRoomSearch?.map((data, idx) => {
            const currentIDX = localStorage.getItem("currentIDX");
            return (
              <div
                key={idx}
                onClick={() => handleSession(data?.session, idx)}
                className={`${((theSessionID || sessionID) || chatSession[0]?.session) === data?.session && "bg-gray-300 dark:bg-gray-800"} cursor-pointer flex justify-between items-center p-3 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg relative`}
              >
                <div className="w-16 h-16 relative flex flex-shrink-0">
                  <img className="shadow-md rounded-full w-full h-full object-cover" src={data?.logo ? `${process.env.REACT_APP_UPLOAD_LOCATION}${data?.logo}` : `${process.env.REACT_APP_UPLOAD_LOCATION}chat_logo.png`} alt="Room Image" />
                  {/*<div className="absolute bg-gray-100 dark:bg-gray-900 p-1 rounded-full bottom-0 right-0">
                    <div className="bg-green-500 rounded-full w-3 h-3"></div>
                  </div>*/}
                </div>
                <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
                  <p title={data?.title} className="truncate font-bold">{data?.title}</p>
                  <div className="flex items-center text-sm font-light text-gray-400">
                    <div className="w-[200px]">
                      <p className="truncate">{data.convo[0]?.type === "img" ? `${data.convo[0]?.sender} sent a photo.` : data.convo[0]?.message}</p>
                    </div>
                    <p className="ml-2 whitespace-no-wrap">{getTimeDiff(data.convo[0]?.created)}</p>
                  </div>
                </div>
                {/*<div className="bg-blue-700 w-3 h-3 rounded-full flex flex-shrink-0 hidden md:block group-hover:block"></div>*/}
              </div>
            );
          })}

          {/*
          // Normal
          <div className="cursor-pointer flex justify-between items-center p-3 bg-gray-300 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg relative">
            <div className="w-16 h-16 relative flex flex-shrink-0">
              <img className="shadow-md rounded-full w-full h-full object-cover"
                src="https://randomuser.me/api/portraits/women/33.jpg"
                alt=""
              />
            </div>
            <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
              <p>Scarlett Johansson</p>
              <div className="flex items-center text-sm text-gray-600">
                <div className="w-[170px]">
                  <p className="truncate">You sent a photo.</p>
                </div>
                <p className="ml-2 whitespace-no-wrap">Just Now</p>
              </div>
            </div>
          </div>

          // With seen right side effect
          <div className="cursor-pointer flex justify-between items-center p-3 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg relative">
            <div className="w-16 h-16 relative flex flex-shrink-0">
              <img className="shadow-md rounded-full w-full h-full object-cover"
                src="https://randomuser.me/api/portraits/women/23.jpg"
                alt="User2"
              />
            </div>
            <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
              <p>Emma Watson</p>
              <div className="flex items-center text-sm text-gray-600">
                <div className="w-[170px]">
                  <p className="truncate">You sent a video.
                  </p>
                </div>
                <p className="ml-2 whitespace-no-wrap">11 Feb</p>
              </div>
            </div>
            <div className="w-4 h-4 flex flex-shrink-0 hidden md:block group-hover:block">
              <img className="rounded-full w-full h-full object-cover" alt="user2" src="https://randomuser.me/api/portraits/women/23.jpg" />
            </div>
          </div>

          // Group chat style
          <div className="cursor-pointer flex justify-between items-center p-3 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-lg rounded-lg relative">
            <div className="w-16 h-16 relative flex flex-shrink-0">
              <img className="shadow-md rounded-full w-10 h-10 object-cover absolute ml-6"
                src="https://randomuser.me/api/portraits/men/22.jpg"
                alt="User2"
              />
              <img className="shadow-md rounded-full w-10 h-10 object-cover absolute mt-6"
                src="https://randomuser.me/api/portraits/men/55.jpg"
                alt="User2"
              />
              <div className="absolute bg-gray-100 dark:bg-gray-900 p-1 rounded-full bottom-0 right-0">
                <div className="bg-green-500 rounded-full w-3 h-3"></div>
              </div>
            </div>
            <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block text-gray-700 dark:text-gray-100">
              <p>TailwindCSS Group</p>
              <div className="flex items-center text-sm text-gray-600">
                <div className="w-[170px]">
                  <p className="truncate">Adam Hurray, Version 2 is out now!!.</p>
                </div>
                <p className="ml-2 whitespace-no-wrap">23 Jan</p>
              </div>
            </div>
          </div>*/}

        </div>
      </section>
    </>
  );
};

export default LeftSide;