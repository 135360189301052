import { useState, useRef } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const Auth = () => {

  const captchaRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [signUP, setSignUP] = useState(false);

  return (
    <>
      <SignIn
        loading={loading}
        captchaRef={captchaRef}
        setSignUP={setSignUP}
        setLoading={setLoading}
      />

      {signUP && (
        <SignUp
          loading={loading}
          captchaRef={captchaRef}
          setSignUP={setSignUP}
          setLoading={setLoading}
        />
      )}

    </>
  );
};

export default Auth;