import { useState } from "react";
import moment from 'moment';

const Rooms = (props) => {

  const {
    allDeletedRooms,
    handleRoomStatus,
    roomStatus
  } = props;

  moment.locale('en');

  const [roomSearch, setRoomSearch] = useState("");

  const searchData = allDeletedRooms?.filter((data) => {
    if (roomSearch === '') {
      return data;
    }
    else {
      return data.session.toLowerCase().includes(roomSearch.toLowerCase())
        || data.title.toLowerCase().includes(roomSearch.toLowerCase())
        || data.role.toLowerCase().includes(roomSearch.toLowerCase())
        || moment.utc(data.created).local().startOf('seconds').fromNow().toLowerCase().includes(roomSearch.toLowerCase());
    }
  });

  return (
    <div className="p-4 sm:ml-64 bg-gray-100 dark:bg-gray-900 h-full overflow-auto">
      <div className="p-4 dark:border-gray-700 mt-5">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">

          <div className="flex items-center justify-between pb-4">
            <div>
              <div
                id="dropdownRadio"
                className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                data-popper-escaped=""
                data-popper-placement="top"
                style={{ position: "absolute", inset: "auto auto 0px 0px", margin: "0px", transform: "translate3d(522.5px, 3847.5px, 0px)" }}
              >
              </div>
            </div>
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                onChange={(e) => setRoomSearch(e.target.value)}
                type="text"
                placeholder="Search for rooms"
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
                <th scope="col" className="px-6 py-3">
                  Session
                </th>
                <th scope="col" className="px-6 py-3">
                  title
                </th>
                <th scope="col" className="px-6 py-3">
                  role
                </th>
                <th scope="col" className="px-6 py-3">
                  status
                </th>
                <th scope="col" className="px-6 py-3 text-right">
                  Created
                </th>
              </tr>
            </thead>
            <tbody className="overflow-hidden">

              {searchData.reverse().map((data, idx) => {
                return (
                  <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="w-4 p-4">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input onChange={(e) => handleRoomStatus(e, data.id)} defaultChecked={roomStatus(data.status)} type="checkbox" value="" className="sr-only peer" />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </td>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {data.session}
                    </th>
                    <td className="px-6 py-4">
                      {data.title}
                    </td>
                    <td className="px-6 py-4">
                      {data.role}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className={`h-2.5 w-2.5 rounded-full ${roomStatus(data.status) ? `bg-green-500` : `bg-red-500`} mr-2`}></div>
                        {roomStatus(data.status) ? "On" : "Off"}
                      </div>
                    </td>
                    <td title={moment(data.created).format('MMMM D, yyyy h:mm a')} className="cursor-help px-6 py-4 text-right">
                      {moment.utc(data.created).local().startOf('seconds').fromNow()}
                    </td>
                  </tr>
                );
              })}

            </tbody>
          </table>
        </div>


      </div>
    </div>
  );
};

export default Rooms;