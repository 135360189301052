import { useState } from "react";
import { toastEffect } from "../toast";
import axios from "axios";

const AccountModal = (props) => {

  const {
    setAccountModalShow,
    accountData,
    avatars,
    setAvatars,
    setRefreshAccount,
    setSelectedOption,
    selectedOption,
  } = props;

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [alias, setAlias] = useState(null);
  const [contact, setContact] = useState(null);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  //console.log(selectedOption.value);

  const options = [
    { value: 'user', label: 'User' },
    { value: 'admin', label: 'Admin' }
  ];

  function emptyFunction(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  async function handleSaveAccount(e) {
    e.preventDefault();
    e.stopPropagation();

    if (selectedOption === null || !firstName === null || !lastName === null || !alias === null || !contact === null) {
      return toastEffect("error", "Empty field please check.");
    }
    if (selectedOption === "" || !firstName === "" || !lastName === "" || !alias === "" || !contact === "") {
      return toastEffect("error", "Empty field please check.");
    }
    //console.log(selectedOption.value, firstName, lastName, alias, contact);
    setIsSaving(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}updateAccount`, {
        accountID: accountData.accountID,
        selectedOption: selectedOption,
        firstName: firstName || accountData.accountFirstName,
        lastName: lastName || accountData.accountLastName,
        alias: alias || accountData.accountAlias,
        contact: contact || accountData.accountContact
      }).then((result) => {
        setRefreshAccount(oldval => oldval + 1);
        setIsSaving(false);
        toastEffect("success", "Saved successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101919");
      //console.log(err);
    }
  }

  async function removeImage(id, imageName) {
    if (!id || !imageName) {
      return;
    }

    setLoadingAvatar(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}deleteImage`, {
        dataID: id,
        imageName: imageName
      }).then(() => {
        setLoadingAvatar(false);
        const newAvatars = avatars.filter((avatar) => avatar.avatar !== imageName);
        setAvatars(newAvatars);
        toastEffect("success", "Deleted successfully");
      });
    } catch (err) {
      toastEffect("error", "Error! #101920");
      //console.log(err);
    }
  }

  return (
    <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="h-modal overflow-auto fixed top-0 left-0 right-0 z-50 w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0 md:h-full">
      <div className="relative h-full w-full max-w-2xl md:h-auto">

        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">

          <div className="flex items-start justify-between rounded-t border-b p-4 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Account Details</h3>
            <button onClick={() => setAccountModalShow(false)} type="button" className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
              <i className="fa-solid fa-xmark" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form onSubmit={isSaving ? emptyFunction : handleSaveAccount}>
            <div className="container mx-auto px-3 modal-content">
              <section className="py-8 px-4">



                <div className="grid md:grid-cols-3 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <input onChange={(e) => setFirstName(e.target.value)} defaultValue={accountData.accountFirstName} type="text" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      First name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input onChange={(e) => setLastName(e.target.value)} defaultValue={accountData.accountLastName} type="text" name="floating_last_name" id="floating_last_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="floating_last_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Last name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input onChange={(e) => setAlias(e.target.value)} defaultValue={accountData.accountAlias} type="text" name="floating_company" id="floating_company" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="floating_company" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Alias
                    </label>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <input onChange={(e) => setContact(e.target.value)} defaultValue={accountData.accountContact} type="text" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="floating_phone" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Contact
                    </label>
                  </div>
                  {/*<div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="floating_company" id="floating_company" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="floating_company" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Alias</label>
                  </div>*/}
                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      id="countries"
                      required
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {options.map((option, idx) => {
                        //if (accountData.accountType !== option.value) {
                        return (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        );
                        //}
                      })}
                    </select>
                  </div>
                </div>

                {/*<div className="relative z-0 w-full mb-6 group">
                  <input type="email" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input type="password" name="floating_password" id="floating_password" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="floating_password" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input type="password" name="repeat_password" id="floating_repeat_password" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="floating_repeat_password" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm password</label>
                </div>*/}

                <div className="-mx-4 -mb-8 flex flex-wrap justify-center">

                  {avatars.map((data, idx) => {
                    return (
                      <div key={idx} className="mb-1 px-0 mr-1 md:w-1/4 relative">
                        <img className="rounded shadow-md w-full h-full object-cover object-center hover:scale-105" src={`${process.env.REACT_APP_UPLOAD_LOCATION}${data.avatar}`} alt="img" />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 opacity-80 bg-gray-600 rounded">
                          {/*{accountData.accountAvatar === data.avatar ? (
                            <i className="fa-solid fa-circle-check text-green-500" />
                          ) : (
                            <>
                              <i title="Set avatar" className="fa-solid fa-check-double mr-1 cursor-pointer text-green-500 hover:text-green-600" />
                              <i title="Delete Image" className="fa-solid fa-trash ml-1 cursor-pointer text-red-500 hover:text-red-600" />
                            </>
                          )}*/}
                          {loadingAvatar ? (
                            <i className="fa-solid fa-spinner fa-spin-pulse" />
                          ) : (
                            <i onClick={() => removeImage(data.id, data.avatar)} title="Delete Image" className="fa-solid fa-trash ml-1 cursor-pointer text-red-500 hover:text-red-600" />
                          )}
                        </div>
                      </div>
                    );
                  })}

                </div>

                {/*<button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Submit
                </button>*/}

              </section>
            </div>

            <div className="relative flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
              {/*<button type="submit" className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600">
                Save
              </button>*/}
              <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {isSaving ? (<i className="fa-solid fa-spinner fa-spin-pulse" />) : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
};

export default AccountModal;