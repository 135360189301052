import { Outlet } from "react-router-dom";
import Header from "../pages/Header";

const Layout = () => {

  return (
    <div className="h-screen w-full flex antialiased text-gray-200 bg-gray-50 dark:bg-gray-900 overflow-hidden">
      <div className="flex-1 flex flex-col">

        <Header />
        <Outlet />

      </div>
    </div >
  );
};

export default Layout;